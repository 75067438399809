@media screen and (max-width: 680px) {
	.body-class {
		background-color: #F1F5F8;
		background-size: cover;
	}
}

#register-form,
#auth {
	display: flex;
	height: 100%;

	.back-button {
		position: absolute;
		top: 30px;
		left: 30px;
		width: 30px;
		height: 20px;
		text-align: center;
		cursor: pointer;
		z-index: 4;

		img {
			width: 22px;
		}

		@media screen and (max-width: 680px) {
			left: 7%;
		}
	}

	.content-box {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		height: 100vh;
		padding-top: 8%;
		position: relative;

		@media screen and (max-width: 320px) {
			height: 100%;
		}

		@media screen and (max-width: 680px) {
			padding-top: 20%;
		}

		.logo-box {
			text-align: center;
			position: absolute;
			bottom: 4%;
			left: calc(50% - 50px);
			z-index: 2;

			.logo {
				width: 100px;
			}
		}
	}

	.form-content {
		display: flex;
		justify-content: center;
		background-color: #F1F5F8;
		transition: all 0.2s ease-in;

		@media screen and (max-width: 680px) {
			padding-bottom: 1rem;

			&:before {
				content: '';
				background: url('../images/signup/mobile-bg-overlay.png') no-repeat;
				background-size: cover;
				width: 100%;
				height: 100%;
				position: fixed;
				right: 0;
				bottom: 0;

				@media screen and (max-width: 320px) {
					background-image: none;
				}
			}
		}

		.account-link {
            &.right-side {
                text-decoration: none;

                &a, a {
                    color: rgb(106, 114, 114) !important;
                }

                .active-locale {
                    font-weight: bold;
                }

                .account-link-action {
                    margin-right: 20px;
                    text-decoration: underline;
                }
            }

			font-size: 0.8125rem; /*13px*/
			color: rgb(106, 114, 114);
			text-decoration: underline;
			position: absolute;
			right: 2rem;
			top: 1.5rem;
		}

		.form-content-box {
			width: 50%;
			position: relative;

			@media screen and (max-width: 680px) {
				width: 85%;
			}

			@media screen and (min-width: 680px) and (max-width: 1400px) {
				width: 75%;
			}

			.slider {
				position: relative;
			}

			.slide-enter-active, .slide-leave-active {
				transition: all 300ms cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
			}

			.slide-enter {
				transform: translateX(50%);
				opacity: 1;

				@media screen and (max-width: 680px) {
					display: block;
				}
			}

			.slide-leave-active {
				transform: translateX(-50%);
				opacity: 0;

				@media screen and (max-width: 680px) {
					display: none;
				}
			}
		}

		.register-step {

			// Title
			h1.title {
				font-size: 36px;
				line-height: 40px;
			}

			.content {
				p {
					font-size: 18px;
					font-weight: 300;
					line-height: 29px;
				}
			}

			.form {
				padding-bottom: 2rem;
				.check {
					box-shadow: none;
				}
			}

			.field {
				margin-bottom: 1rem;
			}

			.button {
				margin-top: 0.75rem;
			}

            .items-list {
                margin-bottom: 0;
            }

			.password-box {
				position: relative;

				.password-info {
					position: absolute;
					right: 20px;
					top: 14px;
					color: rgba(106, 114, 114, 0.6);
					font-size: 0.875rem; /*14px*/
					z-index: 4;
				}

				.error {
					& + .password-info {
						right: 40px;
					}
				}
			}

			.confirm {
				align-items: flex-start;
				margin-top: 0.75rem;
				margin-bottom: 0.75rem;
				font-family: $family-sans-serif;

				.control-label {
					font-size: 0.8125rem; /*13px*/
					color: rgba(106, 114, 114, 0.702);
					line-height: 1.385;
					font-weight: 300;

					a {
						color: rgba(106, 114, 114, 0.702);
						text-decoration: underline;
					}
				}
			}

			.next-step-link {
				font-size: 0.8125rem; /*13px*/
				color: rgba(106, 114, 114, 0.702);
				text-decoration: underline;
				display: inline-block;
				padding-left: 1.5rem;
				margin-top: .5rem;
			}

			.branches {
				.branche-btn {
					margin-top: 0.8rem;
					margin-right: 0.4rem;
					font-size: 1rem;
                    white-space: nowrap;

					@media screen and (max-width: 680px) {
						font-size: 0.8rem;
						padding: 0.3rem 1rem;
					}

					.check-mark {
						display: none;
					}

					&.is-success {
						padding-left: 14px;
						padding-right: 14px;

						@media screen and (max-width: 680px) {
							padding-left: 8px;
							padding-right: 8px;
						}

						.check-mark {
							width: 15px;
							height: 11px;
                            display: inline-block;

							@media screen and (max-width: 680px) {
								width: 12px;
								height: 8px;
							}
						}
					}
				}

				.branche-input {
					margin-top: 1.5rem;
					margin-bottom: 0;
				}

				.submit-btn {
					margin-top: 1.5rem;
				}
			}

			.results {
				color: rgb(106, 114, 114);
				font-size: 0.875rem;
				margin-bottom: 1.5rem;

				.edit-link {
					color: rgb(158, 165, 165);
					padding-left: 1rem;
					text-decoration: underline;
				}
			}

		}

		.progress-bar {
            font-size: 0;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;

			@media screen and (max-width: 680px) {
				top: 0;
				bottom: unset;
				width: 100%;
			}

			.progress {
                width: 100%;
				border-radius: 0;
				height: 0.5rem;
                display: block;
                -webkit-appearance: none;

				&::-webkit-progress-bar {
					background-color: rgba(59, 193, 115, 0.2);
				}
				&::-moz-progress-bar {
					background-color: rgba(59, 193, 115, 0.2);
				}
				&::-ms-fill {
					background-color: rgba(59, 193, 115, 1);
					border: none;
				}
			}
		}

		// Video
		&.video {
			@media (min-width: 1150px) and (max-width: 1680px) {
				padding-top: 5%;
			}
		}
	}

	.info-content {
		background-color: $success;
		display: flex;
		flex-direction: column;
		position: relative;

		@media screen and (max-width: 680px) {
			display: none;
		}

		.info-content-box {
			width: 50%;
			align-self: center;
			z-index: 2;

			@media screen and (max-width: 1350px) {
				width: 75%;
			}

			.title {
				font-size: 36px;
				line-height: 40px;
				color: #FFFFFF;
			}

			.content {
				p {
					color: #FFFFFF;
					font-size: 18px;
					line-height: 29px;
					font-weight: 300;
				}

				ul {
					list-style: none;
					margin-left: 0;

					li {
						color: #FFFFFF;
						position: relative;
						padding-left: 2em;
						font-size: 18px;
						font-weight: 300;
						line-height: 29px;

						&:after {
							content: '';
							background: url('../images/signup/check-mark.svg') no-repeat;
							width: 25px;
							height: 25px;
							position: absolute;
							left: 0;
							top: 0;
						}
					}
				}
			}
		}

		.info-image-box {
			width: 80%;
			margin-top: 3.5rem;
			align-self: center;
			text-align: center;
			z-index: 2;
		}

		&.success {
			background-color: $success;
			transition: all 0.2s ease-in;

			&:before {
				opacity: 0.6;
				pointer-events: none;
			}

			&:after {
				opacity: 0.6;
			}

			a {
				color: #FFFFFF;
			}

			.success-icon {
				margin-top: 5rem;

				.sa {
					&-success {
						margin: 0 auto;
						border-radius: 50%;
						box-sizing: content-box;
						height: 150px;
						padding: 0;
						position: relative;
						width: 150px;
						&-placeholder {
							border-radius: 50%;
							border: 4px solid #259A56;
							background-color: #259A56;
							box-sizing: content-box;
							height: 150px;
							width: 150px;
							left: -4px;
							position: absolute;
							top: -4px;
							z-index: 2;
							opacity: 0;
							animation: pulse 0.2s ease 0.3s forwards;
						}
						&-tip, &-long {
							background-color: #FFFFFF;
							border-radius: 4px;
							height: 12px;
							position: absolute;
							z-index: 3;
						}
						&-tip {
							transform: rotate(45deg);
							animation: animateSuccessTipBig .75s ease 0.3s forwards;
						}
						&-long {
							transform: rotate(-45deg);
							animation: animateSuccessLongBig .75s ease 0.3s forwards;
						}
					}
				}
			}

			// Success Animations
			@keyframes pulse {
				0% {
					opacity: 1;
					transform: scale(1);
				}
				50% {
					opacity: 0.5;
					transform: scale(1.3);
				}
				100% {
					opacity: 1;
					transform: scale(1);
				}
			}
			@keyframes animateSuccessTipBig {
				0%, 54% {
					width: 0;
					left: 14px;
					top: 41px;
				}
				70% {
					width: 60px;
					left: 6px;
					top: 62px;
				}
				84% {
					width: 26px;
					left: 46px;
					top: 85px;
				}
				100% {
					width: 40px;
					left: 34px;
					top: 80px;
				}
			}
			@keyframes animateSuccessLongBig {
				0%, 65% {
					width: 0;
					right: 89px;
					top: 94px;
				}
				84% {
					width: 85px;
					right: 17px;
					top: 65px;
				}
				100% {
					width: 70px;
					right: 30px;
					top: 70px;
				}
			}
		}

		&.video {
			@media (min-width: 1150px) and (max-width: 1680px) {
				padding-top: 0;
			}
		}
	}

	.tablet-video {
		position: relative;
		z-index: 2;
		max-width: 600px;
		margin: 0 auto;
		width: 100%;

		@media (min-width: 1150px) and (max-width: 1680px) {
			top: 8%;
		}

        img {
            position: relative;
            left: -25px;

            @media (max-width: 768px) {
                left: -12.5px;
            }
        }

/*			// Badge
			&:before {
				width: 188px;
				height: 188px;
				background-image: url('../images/signup/badge.svg');
				background-size: 100% 100%;
				background-position: center center;
				background-repeat: no-repeat;
				content: '';
				position: absolute;
				top: 70px;
				left: -90px;
				z-index: 2;
				transform: translateY(-90px);
				opacity: 0;
				-webkit-animation-name: badgeBounce;
				-moz-animation-name: badgeBounce;
				-o-animation-name: badgeBounce;
				animation-name: badgeBounce;
				-webkit-animation-duration: 1s;
				-moz-animation-duration: 1s;
				-o-animation-duration: 1s;
				animation-duration: 1s;
				-webkit-animation-iteration-count: 1;
				-moz-animation-iteration-count: 1;
				-o-animation-iteration-count: 1;
				animation-iteration-count: 1;
				-webkit-animation-fill-mode: forwards;
				-moz-animation-fill-mode: forwards;
				-o-animation-fill-mode: forwards;
				animation-fill-mode: forwards;
				-webkit-animation-delay: 1.5s;
				-moz-animation-delay: 1.5s;
				-o-animation-delay: 1.5s;
				animation-delay: 1.5s;

				@media (min-width: 1150px) and (max-width: 1680px) {
					top: 30px;
					left: -90px;
				}

				@media (max-width: 1024px) {
					top: 50px;
					left: -70px;
					width: 150px;
					height: 150px;
				}

				@media (max-width: 992px) {
					top: -20px;
					left: -70px;
					width: 130px;
					height: 130px;
				}

				@media (max-width: 768px) {
					left: -20px;
				}
			}*/
	}
}

#auth {
	.login-form {
		.password-forgotten {
			font-size: 0.8125rem; /*13px*/
			padding-top: 1rem;
			padding-bottom: 1rem;

			.password-forgotten-link {
				color: $primary;
				cursor: pointer;
			}
		}
	}
}

#auth {
	.success-icon {
		text-align: center;
		position: relative;

		.avatar {
			z-index: 1;
			position: relative;
			border: 12px solid #259A56;
			border-radius: 50%;
		}
	}

	@media screen and (max-width: 680px) {
		.content-box {
			min-height: 100%;
			height: 100%;
		}
	}
}

// Iframe bg badge animation
@keyframes badgeBounce {
	0% {
		opacity: 0;
		transform: translateY(-90px);
	}
	25% {
		opacity: 1;
		transform: translateY(0);
	}
	50% {
		transform: translateY(-15px);
	}
	75% {
		transform: translateY(0);
	}
	85% {
		transform: translateY(-5px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
