#page-search {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > section {
        background: url('../images/redeem/presents.svg') no-repeat center top 45px;
        background-size: 951px;

        @media screen and (max-width: 1215px) {
            background-size: 100%;
        }

        &.success,
        &.error {
            height: 100vh;
            background-size: 951px, 100%;
            background-repeat: no-repeat;
            background-position: center top 45px, center center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow-y: scroll;
            overflow-x: hidden;
            z-index: 150;

            @media screen and (max-width: 1215px) {
                background-size: 100%, 100%;
            }

            &:before {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: -1;
            }
        }

        &.success {
            background-image: url('../images/redeem/presents-light.svg'), linear-gradient(135deg, rgb(59, 193, 115) 0%, rgb(49, 139, 86) 100%);
        }

        &.error {
            background-image: url('../images/redeem/presents-light.svg'), linear-gradient(135deg, rgb(209, 50, 50) 0%, rgb(177, 22, 22) 100%);
        }

        .search-box {
            padding: 2rem 1.5rem;
        }

        .title-container {
            max-width: 500px;
            margin: 0 auto;
            padding-top: 6rem;

            @media screen and (max-width: 768px) {
                padding: 1.5rem 0;
            }

            .title-box {
                text-align: center;
                padding-bottom: 1.5rem;

                @media screen and (max-width: 768px) {
                    padding-bottom: .5rem;
                }

                h1 {
                    font-weight: 600;
                    font-size: 2.3em;

                    @media screen and (max-width: 1215px) {
                        font-size: 2em;
                    }

                    @media screen and (max-width: 768px) {
                        font-size: 1.5em;
                        margin-bottom: .5rem;
                    }
                }

                p {
                    @media screen and (max-width: 768px) {
                        font-size: 1em;
                    }
                }

                &.result {
                    h1 {
                        color: #fff;
                        font-size: 2.9rem;

                        @media screen and (max-width: 1215px) {
                            font-size: 2em;
                        }

                        @media screen and (max-width: 768px) {
                            font-size: 1.5em;
                        }
                    }

                    p {
                        color: #fff;

                        @media screen and (max-width: 768px) {
                            font-size: 1em;
                        }
                    }

                    .already-used-message {
                        margin-top: -.7rem;

                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        .search_container {
            position: relative;
            padding-bottom: 8rem;

            @media screen and (max-width: 768px) {
                padding-bottom: 0;
            }

            .giftcard-input {
                input {
                    font-size: 1.333em;
                    border-radius: 5px;
                    box-shadow: 0px 5px 27px 3px rgba(213, 213, 213, 0.35);
                    height: 3.1em;
                    padding-left: 1.5rem;

                    @media screen and (max-width: 768px) {
                        font-size: 1.1em;
                    }
                }

                .icon {
                    width: 45px;
                    padding-top: 1.1rem;
                    margin-right: 1.5rem;

                    img {
                        width: 45px;
                    }

                    @media screen and (max-width: 768px) {
                        width: 30px;
                        padding-top: .9rem;
                        margin-right: 1rem;

                        img {
                            width: 30px;
                        }
                    }
                }
            }

            > .control:after {
                width: 1.8rem;
                height: 1.8rem;
                top: 1.1rem;
                right: 5.2rem;
                z-index: 3;

                @media screen and (max-width: 768px) {
                    width: 1.5rem;
                    height: 1.5rem;
                    top: 1rem;
                    right: 3.5rem;
                }
            }

            .control {
                .input.is-large {
                    position: relative;
                    z-index: 2;
                }
            }
        }
    }
}


.search-result-box {

	.result-box {
		padding: 3rem 2rem;
		margin: 1rem;
		border-radius: 5px;
		background-color: #fff;
		box-shadow: 0px 16px 76px 0px rgba(213, 213, 213, 0.004);

		@media screen and (max-width: 768px) {
			padding: 2rem 1rem;
		}

		.result-box-title {

		}

		.result-box-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 1.2rem;

			.package-input-box,
            .amount-input-box {
				width: 65%;
				position: relative;

				@media screen and (max-width: 768px) {
					width: 80%;
				}

				.currency-box {
					position: absolute;
					height: 100%;
					width: 3.5rem;
					border-right: 1px solid #EFEFEF;
					display: flex;
					justify-content: center;
					align-items: center;

					.currency-symbol {
						font-size: 1.5rem;
					}
				}

				.currency-symbol,
				.amount-input {
					font-family: 'nexa';
					font-weight: 600;
					color: #1F1F1F;
				}

				.amount-input {
					font-size: 2.4rem;
					width: 100%;
                    height: 5.25rem;
					border-radius: 5px;
					border: 1px solid #EFEFEF;
					padding: .9rem .5rem .9rem 5rem;

					@media screen and (max-width: 768px) {
						font-size: 2rem;
					}

					&:focus,
					&:active {
						outline: none;
					}
				}

				&.is-error {
					.currency-box,
					.amount-input {
						border-color: #d13232;
					}
				}

				.error-tooltip {
					position: absolute;
					right: 0px;
					top: 23px;

					.tooltip.is-right.is-danger:before {
						border-right: 6px solid #d13232;
					}

					.tooltip.is-danger:after {
						background: #d13232;
						font-size: 1rem;
						border-radius: 5px;
						box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.27);
						padding: .5rem .8rem .5rem .8rem;
					}
				}
			}

			.residual-value-box {
				padding: 1rem;
				color: #6a7272;
				display: flex;
				flex-direction: row;
				align-items: center;

				.loading-dots,
				.residual-value {
					padding-left: 6px;
				}
			}

            .card-expiration {
                color: #6a7272;
                text-align: center;
                margin-top: -1rem;
            }

			.promotional-box {
				padding: 1.5rem 0 .5rem;

				.label {
					color: #6a7272;
				}
			}
		}

        .result-box-package {
            margin: 1.2rem -2rem 0 -2rem;
            color: #6a7272;
        }

		.result-box-locations {
			margin: 1.2rem -2rem 0 -2rem;

			@media screen and (max-width: 768px) {
				margin: 1.2rem -1rem 0 -1rem;
			}

			.location-row {
				text-align: center;
				padding: 1rem 0;
				border-top: 1px solid rgb(221, 221, 221);
				border-bottom: 1px solid rgb(221, 221, 221);
				background-color: rgb(250, 250, 250);
				color: #6a7272;

				.edit-link {
					color: #b4c0c0;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			.location-edit-box {

				.location-row {
					transition: background .1s ease-out;
					cursor: pointer;
					display: flex;
					margin-bottom: -1px;
					justify-content: center;
					align-items: center;

					&:hover {
						background-color: rgba(59, 193, 115, .1);
						border-color: rgb(59, 193, 115);
						position: relative;
						z-index: 1;

						.checkmark {
							opacity: 1;
						}
					}

					&.active {
						.checkmark {
							opacity: 1;
						}
					}

					.checkmark {
						transition: opacity .1s ease-out;
						display: inline;
						width: 20px;
						margin-left: calc(-20px - 0.5rem);
						margin-right: .5rem;
						opacity: 0;
					}
				}
			}
		}

		.result-box-button {
			margin-top: 2rem;
		}

		.result-box-tip {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 2rem;

			.result-tip-avatar {
				display: flex;
				align-items: center;

				img {
					width: 130px;
					border: 5px solid $grey-lighter;
					border-radius: 50%;
				}
			}

			.result-tip-tooltip {
				box-shadow: 0px 15px 43px 0px rgba(213, 213, 213, 0.67);
				border: 1px solid rgb(221, 221, 221);
				background-color: #fff;
				border-radius: 5px;
				padding: 1rem;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					width: 10px;
					height: 10px;
					background-color: #fff;
					transform: rotate(45deg);
					border-left: 1px solid rgb(221, 221, 221);
					border-bottom: 1px solid rgb(221, 221, 221);
					top: calc(50% - 5px);
					right: auto;
					bottom: auto;
					left: - 6px;
				}
			}
		}

		.success-icon-box {
			margin-top: 2rem;

			.success-icon {
				position: relative;

				svg {
					position: absolute;
					top: -10px;
					left: -10px;
					width: 170px;
				}

				.circle {
					stroke-dasharray: 1000;
					stroke-dashoffset: 1000;
					animation: dash 8s linear forwards;
				}

				@keyframes dash {
					to {
						stroke-dashoffset: 0;
					}
				}

				.sa {
					&-success {
						margin: 0 auto;
						border-radius: 50%;
						box-sizing: content-box;
						height: 150px;
						padding: 0;
						position: relative;
						width: 150px;

						&-placeholder {
							border-radius: 50%;
							border: 4px solid #259A56;
							background-color: #259A56;
							box-sizing: content-box;
							height: 150px;
							width: 150px;
							left: -4px;
							position: absolute;
							top: -4px;
							z-index: 2;
							opacity: 0;
							animation: pulse 0.2s ease 0.3s forwards;
						}

						&-tip, &-long {
							background-color: #FFFFFF;
							border-radius: 4px;
							height: 12px;
							position: absolute;
							z-index: 3;
						}

						&-tip {
							transform: rotate(45deg);
							animation: animateSuccessTipBig .75s ease 0.3s forwards;
						}

						&-long {
							transform: rotate(-45deg);
							animation: animateSuccessLongBig .75s ease 0.3s forwards;
						}
					}
				}
			}

			// Success Animations
			@keyframes pulse {
				0% {
					opacity: 1;
					transform: scale(1);
				}
				50% {
					opacity: 0.5;
					transform: scale(1.3);
				}
				100% {
					opacity: 1;
					transform: scale(1);
				}
			}
			@keyframes animateSuccessTipBig {
				0%, 54% {
					width: 0;
					left: 14px;
					top: 41px;
				}
				70% {
					width: 60px;
					left: 6px;
					top: 62px;
				}
				84% {
					width: 26px;
					left: 46px;
					top: 85px;
				}
				100% {
					width: 40px;
					left: 34px;
					top: 80px;
				}
			}
			@keyframes animateSuccessLongBig {
				0%, 65% {
					width: 0;
					right: 89px;
					top: 94px;
				}
				84% {
					width: 85px;
					right: 17px;
					top: 65px;
				}
				100% {
					width: 70px;
					right: 30px;
					top: 70px;
				}
			}
		}

		.success-message-box {
			margin-top: 3rem;

			@media screen and (max-width: 768px) {
				margin: 3rem 2rem 0 2rem;
			}

			h1 {
				font-weight: 600;
			}
		}

		.success-button-box {
			margin-top: 4rem;

			@media screen and (max-width: 768px) {
				margin-top: 1.5rem;
			}
		}

		.error-title-box {
			padding: 3rem 2rem 1.2rem 2rem;

			h1 {
				font-weight: 600;
			}
		}

		.error-content-box {
			padding: 0 2rem 2rem 2rem;
		}
	}

	.cancel-box {
		padding: 1rem 2rem 2rem 2rem;

		@media screen and (max-width: 768px) {
			padding: 0rem 1rem 1rem 1rem;
		}

		a {
			font-family: 'nexa';
			font-weight: 600;
			font-size: 1.125rem;
			color: #fff;
			text-decoration: underline;
		}
	}
}

@keyframes blink {
	0% {
		opacity: .2;
	}
	20% {
		opacity: 1;
	}
	100% {
		opacity: .2;
	}
}

.loading-dots span {
	animation-name: blink;
	animation-duration: 1.4s;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
}

.loading-dots span:nth-child(2) {
	animation-delay: .2s;
}

.loading-dots span:nth-child(3) {
	animation-delay: .4s;
}
