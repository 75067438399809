$default-color: #6A7272;
$primary-color: $primary;

.datepicker {

    .mx-datepicker {

        // Only desktop styles datepicker
        @media screen and (min-width: 769px) {
            .mx-datepicker-popup {
                display: flex;

                .mx-shortcuts-wrapper {
                    width: 110px;
                    box-shadow: rgba(0, 0, 0, 0.1) 1px 0px;

                    .mx-shortcuts {
                        display: block;
                        padding: .5rem 0;
                        font-size: 12px;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        // Mobile styles datepicker
        @media screen and (max-width: 767px) {
            .mx-datepicker-popup {
                .mx-range-wrapper {
                    width: 100%;
                }

                .mx-calendar-content {
                    width: 100%;
                }
            }
        }
    }

    .control {
        .icon {
            top: 2px;
        }
    }
}

.mx-datepicker-popup {
	position: absolute;
	margin-top: 1px;
	margin-bottom: 1px;
	border: 1px solid #d9d9d9;
	background-color: #fff;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	z-index: 1000;
}

.mx-range-wrapper {
	width: 248px * 2;
	overflow: hidden;
}

.mx-shortcuts-wrapper {
	text-align: left;
	padding: 0 12px;
	line-height: 34px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	.mx-shortcuts {
		background: none;
		outline: none;
		border: 0;
		color: #48576a;
		margin: 0;
		padding: 0;
		white-space: nowrap;
		cursor: pointer;
		&:hover {
			color: mix(#fff, $primary-color, 20%);
		}
		&:after {
			content: '|';
			margin: 0 10px;
			color: #48576a;
		}
	}
}

.mx-datepicker-footer {
	padding: 4px;
	clear: both;
	text-align: right;
	border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.mx-datepicker-btn {
	font-size: 12px;
	line-height: 1;
	padding: 7px 15px;
	margin: 0 5px;
	cursor: pointer;
	background-color: transparent;
	outline: none;
	border: none;
	border-radius: 3px;
}
.mx-datepicker-btn-confirm {
	border: 1px solid rgba(0, 0, 0, 0.1);
	color: #73879c;
	&:hover {
		color: #1284e7;
		border-color: #1284e7;
	}
}

.mx-calendar {
	float: left;
	color: $default-color;
	padding: 6px 12px;
	* {
		box-sizing: border-box;
	}
}

.mx-calendar-header {
	padding: 0 4px;
	height: 34px;
	line-height: 34px;
	text-align: center;
	overflow: hidden;
	> a {
		color: inherit;
		text-decoration: none;
		cursor: pointer;
		&:hover {
			color: mix(#fff, $primary-color, 20%);
		}
	}
	@at-root {
		.mx-icon-last-month,
		.mx-icon-next-month {
			padding: 0 6px;
			font-size: 20px;
			line-height: 30px;
		}
		.mx-icon-last-month {
			float: left;
		}
		.mx-icon-next-month {
			float: right;
		}
		.mx-icon-last-year {
			@extend .mx-icon-last-month
		}
		.mx-icon-next-year {
			@extend .mx-icon-next-month
		}
	}
}

.mx-calendar-content {
	width: 32px * 7;
	height: 32px * 7;
	.cell {
		vertical-align: middle;
		cursor: pointer;
		&:hover {
			background-color: #f1dde3;
		}
		&.actived {
			color: #fff;
			background-color: $primary-color;
		}
		&.inrange {
			background-color: #f1dde3;
		}
		&.disabled {
			cursor: not-allowed;
			color: #ccc;
			background-color: #f3f3f3;
		}
	}
}

.mx-panel {
	width: 100%;
	height: 100%;
	text-align: center;
}

.mx-panel-date {
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
	td, th {
		font-size: 12px;
		width: 32px;
		height: 32px;
		padding: 0;
		overflow: hidden;
		text-align: center;
	}
	td {
		&.today {
			color: mix(#fff, $primary-color, 10%);
		}
		&.last-month,
		&.next-month {
			color: #ddd;
		}
	}
}

.mx-panel-year {
	padding: 7px 0;
	.cell {
		display: inline-block;
		width: 40%;
		margin: 1px 5%;
		line-height: 40px;
	}
}

.mx-panel-month {
	.cell {
		display: inline-block;
		width: 30%;
		line-height: 40px;
		margin: 8px 1.5%;
	}
}

.mx-time-list {
	position: relative; // 定位 offsetParent
	float: left;
	margin: 0;
	padding: 0;
	list-style: none;
	width: 100%;
	height: 100%;
	border-top: 1px solid rgba(0,0,0,.05);
	border-left: 1px solid rgba(0,0,0,.05);
	overflow-y: auto;
	.mx-time-picker-item {
		display: block;
		text-align: left;
		padding-left: 10px;
	}
	&:first-child {
		border-left: 0;
	}
	.cell {
		width: 100%;
		font-size: 12px;
		height: 30px;
		line-height: 30px;
	}
	/* 滚动条滑块 */
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.05);
		border-radius: 10px;
		box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
	}

	&:hover::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.2);
	}
}
