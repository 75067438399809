.gifty-table {

    .icon-label {

        .icon {
            font-size: 20px;
            vertical-align: sub;
        }
    }

    &.text-darker, .text-darker {
        color: $grey-darker;
    }

    //Mobile filter
    .field {
        margin: 0.25rem 0.25rem 1rem 0.25rem;

        .control {
            .select {
                height: unset;

                select {
                    height: unset;
                }
            }

            .button {
                padding: 0.7rem 1.5rem;

                .icon {
                    i {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    .table {
        color: #4A4A4A;
        margin-bottom: 1.5rem;

        thead {
            tr {
                border-bottom: 1px solid $grey-lighter;
                border-collapse: separate;

                th {
                    font-size: 1em;
                    padding: 1em 0.75em 1em 0;
                    border-bottom-width: 1px;
                    border-color: $grey-lighter;

                    &.is-current-sort {
                        border-color: $grey-lighter;
                        position: relative;

                        &:after {
                            content: '';
                            height: 2px;
                            width: 100%;
                            background-color: $grey-lighter;
                            position: absolute;
                            bottom: 0;
                        }
                    }

                    &.is-sortable {
                        position: relative;

                        &:hover {
                            border-color: $grey-lighter;

                            &:after {
                                content: '';
                                height: 2px;
                                width: 100%;
                                background-color: $grey-lighter;
                                position: absolute;
                                bottom: 0;
                            }
                        }
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        tbody {
            tr {
                &:hover {
                    background-color: #FAFAFA;
                }

                &:last-child {
                    td {
                        border-bottom-width: 1px;
                    }
                }

                &.is-selected {
                    color: $grey-darker;
                    background-color: #f6f9fb;
                }
            }

            td {
                padding: 0.75em 0.75em 0.75em 0;
                vertical-align: middle;
                transition: all 0.3s ease-out;
                border-color: $grey-lighter;

                @media screen and (max-width: 768px) {
                    padding-left: 0.75em;
                }

                @media screen and (min-width: 769px) {
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        .tooltip {
            &:after {
                border-radius: 5px;
            }
        }

        .tag {
            border-radius: 5px;
            font-size: 0.875rem;
        }

        .text-ellipsis {
            width: 300px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .text-nowrap {
            white-space: nowrap;
        }
    }

    .pagination {
        .pagination-previous,
        .pagination-next {
            color: rgb(106, 114, 114);

            &:hover {
                color: $primary;
                border: 1px solid $primary;
            }

            &[disabled] {
                background: none;

                &:hover {
                    color: #7A7A7A;
                    border: 1px solid $grey-lighter;
                }
            }
        }

        .pagination-previous {
            i:after {
                @extend .icon;
                display: flex;
                content: "navigate_before";
            }
        }

        .pagination-next {
            i:after {
                @extend .icon;
                display: flex;
                content: "navigate_next";
            }
        }

        .pagination-list {
            .pagination-link {
                color: rgb(106, 114, 114);

                &.is-current {
                    color: $primary;
                    background: none;
                }

                &:hover {
                    color: $primary;
                    border: 1px solid $primary;
                }
            }
        }
    }

    .loading-state {
        margin-top: 8rem;
    }

    &.drag-drop-table {
        .table {

            .drag-drop-column {
                padding-right: 1rem;
                cursor: move;
                cursor: -webkit-grabbing;

                .icon {
                    color: #CCCCCC;
                    font-size: 18px;
                }
            }
        }

        .drag-drop-selection {
            background-color: #FAFAFA;
        }

        .sortable-ghost {
            td {
                &:first-child {
                    padding-left: 20px;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}
