#page-header {

    .header-section {
        background-color: #f1f5f8;
        padding: 4rem 1.5rem;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            padding: 2rem 1.5rem;
        }

        &:before {
            content: '';
            background: url('../images/layout/header-overlay.png') no-repeat;
            width: 517px;
            height: 178px;
            position: absolute;
            left: 0;
            bottom: 0;

            @media screen and (max-width: 1023px) {
                left: -270px;
            }

            @media screen and (max-width: 600px) {
                left: -390px;
            }
        }

        .title {
            font-size: 2.8rem;
            margin-bottom:0;

            @media screen and (max-width: 768px) {
                font-size: 2rem;
            }
        }

        .content {
            margin-top: 1.5rem;
            font-weight:300;
            margin-bottom: 0;
        }
    }

    &.no-bg {
        .header-section {
            background-color: transparent;

            &:before {
                background: none;
            }
        }
    }
}

#wallet-menu {
    position: relative;

    .marker {
        bottom: 0;
        $duration: .3s;
        position: absolute;
        display: none;
        border-bottom: 0.188rem solid $primary;
        height: 0.188rem;
        transition: width $duration, left $duration;
    }

    ul li {
        display: inline-block;
        padding: 0 0 1rem;
        margin: 0 0.4rem 0 0.4rem;
        cursor: pointer;

        a {
            &.is-active {
                color: $primary !important;
            }

            color: #000 !important;
        }
    }
}

#header-menu {
    padding-top: 2rem;

    .inline-menu {
        &.custom {
            position:relative;
            box-shadow: 0 3px 43px 0 rgba(213, 213, 213, 0.47);

            li {
                padding: 0;

                a {
                    display: inline-block;
                    padding: 15px 30px;
                    border-right: 1px solid #ebf0f3;

                    &.is-active {
                        border-bottom: none;
                    }
                }
            }

            .marker {
                bottom:0;
                $duration: .3s;
                position: absolute;
                display:none;
                border-bottom:3px solid $primary;
                height: 3px;
                transition:width $duration, left $duration;
            }
        }
    }
}

.fullscreen-background {
	background-color: #F1F5F8;
	position: relative;
	background-image: url('../images/membership/bg-bottom.png');
	background-repeat: no-repeat;
	background-position: bottom left;
	background-position-x: 200px;
	z-index: 0;

	&:before {
		content: '';
		background: url('../images/membership/bg-left.png') no-repeat;
		width: 524px;
		height: 648px;
		position: absolute;
		left: 0;
		top: 150px;
	}

	&:after {
		content: '';
		background: url('../images/membership/bg-right.png') no-repeat;
		width: 490px;
		height: 560px;
		position: absolute;
		right: 0;
		top: 200px;
		z-index: -1;
	}

	.section {
		z-index: 1;
	}

}
