.items-list {
	margin-bottom: 1.5rem;

	.item-container {
		position: relative;

		.notification {
			margin-top: 1rem;
			margin-bottom: 1rem;
		}

		.item-box {
			display: flex;
			align-items: center;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-bottom: 1px solid rgb(221, 221, 221);

			:first-child {
				flex: 1;
			}

			> div {
				margin-right: 0.5rem;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.item-controls {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.item-text {
		font-size: 0.875rem;
		color: rgb(106, 114, 114);
        align-items: center;

        small {
            color: #d2d2d2;
        }
	}

	// Animations
	.items-list-enter-active, .items-list-leave-active {
		transition: transform .5s ease, opacity .5s ease;
	}

	.items-list-enter, .items-list-leave-to {
		position: absolute;
		transform: translateY(10px);
		width: 100%;
		opacity: 0;

		//transform: translateY(30px);
	}
}
