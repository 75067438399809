@import "icons";

.checkbox {
    display: flex;

    &[disabled] {
        opacity: 0.5;
    }

    input[type=checkbox] {
        position: absolute;
        left: 0;
        opacity: 0;
        outline: none;
        z-index: -1;

        + .check {
            color: $success;
            width: 1.3rem;
            height: 1.3rem;
            margin-right: .5rem;
            flex-shrink: 0;
            background: #FFFFFF;
            border-radius: $radius;
            border: 2px solid $grey-lighter;

            &:after {
                content: "check";
                opacity: 0;
                transform: scale(0);
                font-size: 1.5rem;
                font-weight: 600;
                margin-left: -0.1rem;
                margin-top: -.5rem;
                @extend .icon;
                transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55) transform;
            }
        }

        &:hover + .check {
            border-color: #b5b5b5;
        }

        &:checked + .check:after {
            opacity: 1;
            transform: scale(1);
        }
    }
}
