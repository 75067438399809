

form, .form {

    .file .button.is-success {
        border-radius: 0 0.375rem 0.375rem 0;
        border: 0.063rem solid #E2E2E2;
        background: #FDFDFD;
        color: #1F1F1F !important;
        font-size: 0.8125rem !important;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.375rem;

        &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    .multiple-fields {
        margin-bottom: 0;

        .multiple-fields-box {
            .field {
                margin-bottom: 0 !important;
            }
        }
    }

    .field {
        position: relative;
        margin-bottom: 1rem;

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        &:after {
            content: "";
            opacity: 0;
            transition: all .1s ease;
        }

        .help {
            &.is-danger {
                display: none;
            }
        }

        .hover-area {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 10px;
            right: 9px;
            z-index: 4;
            cursor: pointer;

            .message-box {
                position: relative;

                .error-message {
                    position: absolute;
                    bottom: 5px;
                    right: -15px;
                    background-color: #FFFFFF;
                    padding: 0.5rem 1rem;
                    color: rgb(106, 114, 114);
                    font-size: 0.8125rem; /*13px*/
                    min-width: 170px;
                    border: 1px solid rgb(221, 221, 221);
                    text-align: center;
                    border-radius: 4px;
                    white-space: nowrap;

                    &:first-letter {
                        text-transform: uppercase;
                    }

                    &:before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        background-color: #FFFFFF;
                        transform: rotate(45deg);
                        position: absolute;
                        bottom: -5px;
                        right: 25px;
                        border-width: 1px;
                        border-style: solid;
                        border-bottom-color: rgb(221, 221, 221);
                        border-right-color: rgb(221, 221, 221);
                        border-top-color: #FFFFFF;
                        border-left-color: #FFFFFF;
                    }
                }
            }
        }

        .control {

            &.is-loading:after {
                border-width: 4px;
                height: 1.5em;
                width: 1.5em;
                top: 0.85em;
                right: 0.85em;
            }

            .input,
            .select select,
            .select.select--advanced .select-display,
            .textarea {
                border-radius: 6px;
                color: #6A7272;
                background: #FFFFFF;
                box-shadow: 0px 5px 27px 3px rgba(213, 213, 213, 0.35);
            }

            .select:not(.is-multiple):not(.is-loading)::after {
                border-color: $grey-lighter;
                border-width: 2px;
                border-radius: 0;
            }

            .is-danger {
                border-color: $red;
            }
        }

        .label {
            display: block;
        }

        &.animation {

            .input,
            .button,
            .select select,
            .select.select--advanced .select-display,
            .textarea {
                font-size: 1rem;
                width: 100%;
                height: auto;
                padding: 20px 12px 5px 12px;
            }

            .button {
                color: #FFFFFF;
                padding: 13px 12px 12px 12px;
                height: 100%;
            }

            .hover-area {
                top: 10px;
            }

            .label {
                pointer-events: none;
                position: absolute;
                color: #9EA5A5;
                margin: auto;
                top: 10px;
                left: 12px;
                transition: top ease .1s, font-size ease .1s;
                z-index: 4;
                font-weight: 400;

                overflow: hidden;
                width: 96.1%;
                white-space: nowrap;

                &.active {
                    color: $grey-darker;
                    font-family: 'nexa';
                    font-weight: bold;
                    font-size: 10px;
                    text-transform: uppercase;
                    top: 8px;
                }
            }
        }
    }

    .control {

        &.has-icons-left .input {
            padding-left: 2.5rem;
        }

        &.has-icons-right .input {
            padding-right: 2.5rem;
        }

        &.has-icons-left,
        &.has-icons-right {
            .icon {
                height: 3rem;
                width: 3rem;
                padding: .7rem;
            }
        }
    }

    label.radio {
        font-family: $family-sans-serif;
        font-weight: 200;
    }

    .label {
        font-size: 1rem;

        &:not(:last-child) {
            margin-bottom: 0.3rem;
        }
    }

    .input,
    .textarea,
    .select, .select:not(.is-multiple),
    .select.select--advanced .select-display
    {
        font-size: 1rem;
        width: 100%;
        height: auto;
        padding: $control-padding-vertical $control-padding-horizontal;
    }

    .select, .select:not(.is-multiple) {
        padding: 0;

        select, .select-display, {
            width: 100%;
            height: auto;
        }
    }

    .select.select--advanced {
        select {
            height: 100%;
        }

        .select-display {
            @extend %input-textarea;

            strong {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 95%;
                overflow: hidden;
            }
        }
    }
}
