.vue-tags-input {
    max-width: unset !important;
    width: 100%;

    .ti-input {
        width: 100%;
        background-color: #fff;
        border-radius: 6px;
        border-color: $grey-lighter !important;
        color: #6A7272;
        box-shadow: 0px 5px 27px 3px rgba(213, 213, 213, 0.35);

        &:hover {
            border-color: $grey-light !important;
        }

        .ti-new-tag-input {
            color: #6A7272;
            font-size: 1rem;
            padding: 0.28rem 0;
        }

        ul.ti-tags {
            padding: 0;
            margin: 0;
        }

        .ti-tags .ti-tag {
            color: #6A7272;
            background-color: #eaf0f3;
            font-size: 12px;
            border-radius: 4px;
            font-weight: 600;

            &.ti-invalid, &.ti-tag.ti-deletion-mark {
                color: #FFFFFF;
                background-color: $danger;
            }

            .ti-content {
                padding: 0 5px;
            }
        }
    }

    .ti-autocomplete {
        border-radius: .5rem;
        box-shadow: -2px 4px 10px 1px rgb(74 74 74 / 28%);

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                color: #1F1F1F;
                font-size: .85rem;
                white-space: nowrap;
                text-decoration: none;
                display: block;
                padding: 0.5rem 1rem;
                border-bottom: .1rem solid #e8e8e8;
                font-weight: 700;
                cursor: pointer;
                transition: background-color .3s;

                &:first-of-type {
                    border-radius: .5rem .5rem 0 0;
                }

                &:last-of-type {
                    border-bottom: none;
                    border-radius: 0 0 .5rem .5rem;
                }

                &:first-of-type:last-of-type {
                    border-radius: .5rem;
                }

                &.ti-selected-item,
                &:hover {
                    color: $grey-darker;
                    background: #f7f7f7;
                }
            }
        }
    }
}
