.icon {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    text-rendering: optimizeLegibility;
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 100,
        'opsz' 48;
    -webkit-font-smoothing: antialiased;


    &.is-small {
        font-size: 12px;
    }

    // Colors overrides
    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        &.is-#{$name} {
            color: $color;
        }
    }
}
