$outer-shadow: rgba(0, 0, 0, 0.08) 0 1px 3px;
$outer-shadow-active: rgba(0, 0, 0, 0.12) 0 2px 5px;

.buttons {
    cursor: pointer;
    font-size: 0;
    white-space: nowrap;
    display: inline-block;
    border-radius: $radius;
    box-shadow: $outer-shadow;

    &.is-right {
        justify-content: flex-end;
    }

    .button {
        margin-left: -1px;
        border-radius: 0;
        box-shadow: none;
        position: relative;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.04) 0 2px 5px;
        }

        &:first-child {
            margin-left: 0;
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
        }

        &:last-child {
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;
        }
    }
}

.field {
    .button {
        height: 100%;
    }
}

.button {
    color: #1F1F1F;
    font-size: 13px;
    font-weight: 600;
    font-family: $family-sans-serif;
    text-align: center;
    text-transform: uppercase;
    line-height: 1.5;
    vertical-align: baseline;
    display: inline-block;
    padding: 9px 19px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: $radius;
    box-shadow: $outer-shadow;
    transition: box-shadow .1s ease-in, border .1s ease-in;

    &:disabled {
        opacity: .6;

        &:hover {
            cursor: not-allowed;
        }
    }

    &:hover:not(disabled) {
        cursor: pointer;
        box-shadow: $outer-shadow-active;
        border-color: darken(#DDDDDD, 15%);
        z-index: 1;
    }

    &:active:not(disabled) {
        box-shadow: $outer-shadow-active;
    }

    &.is-text {
        background: none;
        box-shadow: none;
        border: none;

        &:hover:not(disabled) {
            box-shadow: none;
            text-decoration: underline;
        }
    }

    &.is-small {
        font-size: 12px;
        padding: 7px 16px;
    }

    &.is-medium {
        font-size: 14px;
        padding: 10px 22px;
    }

    &.is-large {
        font-size: 14px;
        padding: 13px 25px;
    }

    // Colors overrides
    @each $name, $pair in $colors {
        $color: nth($pair, 1);
        $color-invert: nth($pair, 2);
        &.is-#{$name} {
            color: $color-invert;
            background-color: $color;
            border-color: darken($color, 10%);

            &:hover:not(disabled) {
                border-color: darken($color, 15%);
            }
        }
    }

    &.is-fullwidth {
        width: 100%;
    }

    &.is-rounded {
        font-size: 1.25rem;
        font-family: 'nexa', sans-serif;
        font-weight: normal;
        text-transform: none;
        padding: .5rem 1.5rem;
        border-radius: 24px;
        border-color: transparent;
    }

    @include loadable;

    &.is-loading {
        color: transparent;

        &:after {
            font-size: 0.5rem;
        }
    }

    .icon {
        font-size: 18px;
        vertical-align: text-bottom;

        &:first-child {
            margin-left: -7px;
        }

        &:last-child {
            margin-right: -7px;
        }
    }
}

.item-button {
    cursor: pointer;
    display: flex;
    align-items: center;

    > div {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }

    &:hover {
        .item-icon {
            border-color: rgb(191, 191, 191);
        }
    }

    &.is-disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.3;
    }

    &.is-success {
        .item-icon {
            i {
                color: $success;
            }
        }
    }

    &.is-success:hover {
        .item-icon {
            border-color: rgba(59, 193, 115, 0.5);
            background-color: rgba(59, 193, 115, 0.05);
        }
    }

    &.is-danger {
        .item-icon {
            i {
                color: $danger;
            }
        }
    }

    &.is-danger:hover {
        .item-icon {
            border-color: rgba(232, 19, 72, 0.5);
            background-color: rgba(232, 19, 72, 0.05);
        }
    }

    .item-text {
        color: rgb(106, 114, 114);
        text-decoration: underline;
    }

    .item-icon {
        cursor: pointer;
        font-size: 0;
        line-height: 0;
        color: #9EA5A5;
        padding: 7px;
        display: inline-block;
        background-color: #FFFFFF;
        border-radius: 50%;
        border: 1px solid rgb(221, 221, 221);
        box-shadow: 0 5px 27px 3px rgba(213, 213, 213, 0.35);
        transition: background-color 100ms linear, border 100ms linear;

        i {
            font-size: 17px;
            line-height: 17px;
        }


        &.danger {
            i {
                color: $danger;
            }

            &:hover {
                border-color: rgba(232, 19, 72, 0.5);
                background-color: rgba(232, 19, 72, 0.05);
            }
        }
    }
}
