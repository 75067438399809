#client-authorize {
    background-color: #F1F5F8;

    .logo {
        width: 6rem;
        margin: 0 1.5rem .5rem 1.5rem;
    }

    .column {
        max-width: 460px;
    }

    .card-content {
        text-align: center;
    }

    .account-details {

        img {
            border-radius: 1rem;
            margin-bottom: 0.7rem;
        }

        p {
            color: #4a4a4a;
            margin-bottom: 0.7rem;
        }
    }

    .scopes {
        text-align: left;
        margin: 1.5rem -1.5rem 1.5rem -1.5rem;

        ul li {
            padding: 0.8rem 1.5rem;
            border-bottom: 1px solid #f1f5f8;

            &.heading {
                font-size: 1rem;
                font-weight: 700;
                background: #f3f3f3;
            }

            &:first-child {
                border-top: 1px solid #f1f5f8;
            }
        }
    }

    .button {
        margin-top: 1.5rem
    }
}
