#price-settings {

    .title-box {
        padding-bottom: 1rem;
    }

    .shipping,
    .pricing {

        .content {
            padding-bottom: 2rem;
        }
    }

    .shipping {

        .shipping-container {
            box-shadow: 0 25px 76px 0 rgba(213, 213, 213, 0.8);
            border-radius: 6px;

            .shipping-box {
                text-align: center;
                cursor: pointer;
                transition: all .2s ease;

                .shipping-price {
                    font-size: 1.1rem;
                    font-family: 'nexa';
                    font-weight: 600;
                    padding: 28px 0;
                    line-height: 26px;
                    background: #FCFCFC;
                    border: 1px solid #DDDDDD;
                    border-left-color: #FCFCFC;
                    border-bottom-width: 0;
                    transition: all .1s ease;

                    .currency {
                        font-weight: 400;
                        font-size: 0.8rem;
                        vertical-align: top;
                    }
                }

                .shipping-emoti {
                    padding: 32px 0;
                    background-color: #F0F0F0;
                    border: 1px solid #DDDDDD;
                    border-left-color: #F0F0F0;
                    border-top-width: 0;
                    transition: all .1s ease;

                    img {
                        height: 26px;
                        margin-bottom: -5px;
                        position: relative;
                        z-index: 1;
                    }
                }

                &:hover {
                    box-shadow: 0 25px 76px 0 rgba(213, 213, 213, 0.8);
                    border-radius: 6px;
                    transform: scale(1.05);
                    transform-origin: center;
                    z-index: 2;

                    .shipping-price {
                        border-color: #DDDDDD;
                        border-radius: 6px 6px 0 0;
                        background: #FFFFFF;
                    }

                    .shipping-emoti {
                        border-color: #DDDDDD;
                        border-radius: 0 0 6px 6px;
                        background: #F2F2F2;
                    }
                }

                &.active {
                    box-shadow: 0 25px 76px 0 rgba(213, 213, 213, 0.8);
                    border-radius: 6px;
                    z-index: 3;
                    transform: scale(1.05);
                    transform-origin: center;

                    .shipping-price {
                        color: #FFFFFF;
                        //background-color: rgb(79, 178, 112);
                        //border-color: #46a565;
                        border-top-right-radius: 5px;
                        border-top-left-radius: 5px;
                    }

                    .shipping-emoti {
                        //background-color: rgb(62, 157, 94);
                        //border-color: #46a565;
                        border-bottom-right-radius: 5px;
                        border-bottom-left-radius: 5px;
                        position: relative;

                        &:before {
                            content: '';
                            width: 47px;
                            height: 47px;
                            background-color: #FFFFFF;
                            border-radius: 50%;
                            top: 45px;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            position: absolute;
                            z-index: 0;
                        }
                    }

                    &.bg-dark-green {
                        .shipping-price {
                            background-color: #79AE83;
                            border-color: #79AE83;
                        }

                        .shipping-emoti {
                            background-color: rgb(62, 157, 94);
                            border-color: rgb(62, 157, 94);
                        }
                    }

                    &.bg-green {
                        .shipping-price {
                            background-color: #A0D992;
                            border-color: #A0D992;
                        }

                        .shipping-emoti {
                            background-color: rgb(113, 211, 107);
                            border-color: rgb(113, 211, 107);
                        }
                    }

                    &.bg-yellow {
                        .shipping-price {
                            background-color: #F6DC81;
                            border-color: #F6DC81;
                        }

                        .shipping-emoti {
                            background-color: rgb(250, 210, 71);
                            border-color: rgb(250, 210, 71);
                        }
                    }

                    &.bg-orange {
                        .shipping-price {
                            background-color: #F1AB77;
                            border-color: #F1AB77;
                        }

                        .shipping-emoti {
                            background-color: rgb(250, 146, 69);
                            border-color: rgb(250, 146, 69);
                        }
                    }

                    &.bg-red {
                        .shipping-price {
                            background-color: #EE6249;
                            border-color: #EE6249;
                        }

                        .shipping-emoti {
                            background-color: rgb(255, 86, 62);
                            border-color: rgb(255, 86, 62);
                        }
                    }

                }

                &:first-child {

                    .shipping-price {
                        border-left-color: #DDDDDD;
                        border-top-left-radius: 6px;
                    }

                    .shipping-emoti {
                        border-left-color: #DDDDDD;
                        border-bottom-left-radius: 6px;
                    }
                }

                &:last-child {

                    .shipping-price {
                        border-top-right-radius: 6px;
                    }

                    .shipping-emoti {
                        border-bottom-right-radius: 6px;
                    }
                }
            }
        }
    }

    .pricing {

        @media screen and (max-width: 768px) {
            .title {
                margin-top: 2rem;
            }
        }

        input {
            box-shadow: 0 5px 27px 3px rgba(213, 213, 213, 0.35);
        }
    }

    .button-box {
        text-align: center;
        margin-top: 2rem;
        padding-top: 2rem;
        border-top: 1px solid rgb(221, 221, 221);
    }
}

#collaborations {
    .token-block {
        font-family: 'nexa';
        background-color: $success;
        border-radius: 5px;
        box-shadow: 0 25px 76px 0 rgba(213, 213, 213, 0.8);
        color: #FFFFFF;
        font-size: 2rem;
        font-weight: 600;
        padding: 1rem 1.5rem;
        margin-top: 1rem;
        display: inline-block;
    }

    .collaboration-search {
        margin-top: 2rem;

        .help {
            &.counter {
                display: none;
            }
        }

        .is-loading {
            &:after {
                height: 1.5em;
                width: 1.5em;
                top: 0.8em;
            }
        }

        .input {
            text-transform: uppercase;
        }
    }

    .collaboration-results {
        .result-box {
            padding: 1rem;
            border-radius: 5px;
            box-shadow: 0px 51px 76px 0px rgba(213, 213, 213, 0.65);
            border: 1px solid $grey-lighter;
            background-color: #FFFFFF;
            position: relative;

            .result-label {
                text-transform: uppercase;
                font-size: 0.8em;
                color: rgba(28, 5, 11, 0.8);
            }

            .result-value {
                color: #1F1F1F;
                font-weight: 600;
                font-size: 1.2em;
            }

            .button {
                float: right;
            }
        }
    }

    .collaborations-title {
        margin-top: 2rem;
    }

    .collaboration-list {
        margin-top: 2rem;

        .no-results {
            font-style: italic;
        }
    }

    .remove-popup {
        padding: 2rem 3rem;
        text-align: center;

        .remove-icon {
            font-size: 2rem;
            line-height: 48px;
            background-color: $danger;
            border-radius: 50%;
            display: inline-block;
            width: 50px;
            height: 50px;
            color: #FFFFFF;
        }

        .popup-title {
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
        }
    }
}

#packages {
    .table-wrapper {
        @media screen and (max-width: 1023px) {
            overflow-x: visible;
        }
    }
}

#membership {
    .getting-started {
        height: 100%;
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 span {
            display: inline-block;
            transform: scale(-1, 1);
        }
    }

    .membership-box {
        box-shadow: 0 51px 76px 0 rgba(213, 213, 213, 0.65);
        border-radius: $radius;
        z-index: 1;

        .membership-box-active-title,
        .membership-box-next-title {
            font-size: .9rem;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            top: 1.5rem;
            width: 275px;
            text-align: center;
            z-index: 2;

            strong {
                font-size: 1.4375rem;
                font-family: 'nexa';
                font-weight: 600;
                color: rgb(31, 31, 31);
                display: block;
            }
        }

        &.active {
            .membership-box-body {
                background-color: #FFFFFF;
                border-bottom: 1px solid $grey-lighter;
                opacity: 1;
            }
        }

        .membership-box-header {
            text-align: center;
            text-transform: uppercase;
            font-size: 1.125rem;
            padding: 1.5rem;
            color: #FFFFFF;
            font-family: 'nexa';
            font-weight: 600;
            border-top-right-radius: $radius;
            border-top-left-radius: $radius;

            > div {
                font-size: .8rem;
            }
        }

        .membership-box-body {
            background-color: #F1F5F8;
            position: relative;
            transition: .3s;
            opacity: 0.8;

            .membership-box-price {
                text-align: center;
                font-family: 'nexa';
                font-weight: 600;
                color: rgb(31, 31, 31);
                display: flex;
                justify-content: center;
                padding-top: 6rem;
                position: relative;

                .price-box {
                    display: inline-block;
                    position: relative;
                    line-height: 1;

                    .currency {
                        font-size: 2rem;
                        position: absolute;
                        top: 21px;
                        left: -30px;
                    }

                    .price {
                        font-size: 3rem;
                    }

                    .percent {
                        vertical-align: 4px;
                    }

                    .price-end {
                        font-size: 4.5rem;
                        position: absolute;
                        top: 0px;
                        right: -53px;
                    }
                }
            }

            .membership-box-price-note {
                text-align: center;
                color: rgba(31, 31, 31, 0.502);
                font-size: 1.5rem;
                font-family: 'nexa';
                margin-top: .5rem;
                padding-bottom: 0.4rem;
            }

            .membership-box-cancellation-note {
                color: rgb(60, 191, 115);
                font-weight: bold;
                text-align: center;
                padding-bottom: 1.4rem;
            }

            .membership-box-included {
                height: 48px;
                text-align: center;

                a {
                    color: #3BC173
                }
            }

            .membership-box-item {
                color: #1f1f1f;
                font-size: .9rem;
                border-top: 1px solid $grey-lighter;
                padding: 1rem;
                height: 55px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &.membership-box-item-indent {
                    padding-left: 2.2rem;
                }

                small {
                    color: #787878;
                    font-size: .7rem;
                    display: block;
                }

                .price {
                    font-weight: 600;
                    text-align: right;
                }
            }

            .membership-box-expand {
                cursor: pointer;
                user-select: none;
                font-size: .8rem;
                font-weight: 600;
                padding: .5rem 1rem;
                border-top: 1px solid $grey-lighter;
                background: #f9f9f9;

                &:hover {
                    color: #1f1f1f;
                }

                .icon {
                    font-size: 1rem;
                }
            }
        }

        .membership-box-footer {
            border-bottom-right-radius: $radius;
            border-bottom-left-radius: $radius;
            text-align: center;
            padding: 1.5rem;
            background-color: #FFFFFF;

            .button {
                width: 100%;
            }
        }
    }
}
