// Import Gifty version of Bulma
@import "bulma";
// Perfect scrollbar
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// Helpers
@import "helpers";
// Fonts
@import "nexa";
// Module styles
@import "module";
// Redeem & Spend
@import "redeem-assign";
// Settings pages
@import "settings";
// Builder
@import "builder";
// Register & Login styles
@import "register-login";
// Layout styles
@import "layout";
// Popup global style
@import "popup";
// Content table global style
@import "options-table";
// Treeselect input
@import "treeselect";
// Item box (list items)
@import "items-list";
// Buttons
@import "buttons";
// Icons
@import "icons";
// Table
@import "table";
// Tab view and content
@import "tab-view";
// Form
@import "form";
@import "checkbox";
@import "datepicker";
@import "tags_input";
// Passport Client Permissions
@import "passport-client";

h1, h2, h3, h4, h5 {
    font-family: 'nexa';
    font-weight: bold;
}

label {
    color: $grey-darker;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 2;
}

.notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .button {
        margin-top: 1rem;

        @include tablet {
            margin-top: 0;
        }
    }

    @include tablet {
        flex-wrap: nowrap;
    }
}

p {
    color: #6A7272;
    font-size: 18px;

    a {
        text-decoration: underline;
    }
}

p.help {
    @extend .capitalize;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

[yellow-dot]::after {
    content: '.';
    color: #ECE028;
}

.switch[type=checkbox] {
    outline: 0;
    user-select: none;
    display: inline-block;
    position: absolute;
    opacity: 0;

    &[disabled] + .switch-container {
        cursor: not-allowed;

        + label, &:after, &:before {
            opacity: 0.5;
        }

        &:hover {
            cursor: not-allowed;
        }

    }

    &:checked + .switch-container {

        &:before {
            background: $success;
        }

        &:after {
            left: 1.625rem;
        }
    }

    + .switch-container {
        position: relative;
        display: initial;
        font-size: 1rem;
        line-height: initial;
        padding-left: 3.5rem;
        //padding-top: .2rem;
        cursor: pointer;

        + label.label {
            cursor: pointer;
            font-weight: 400;
            font-family: $family-sans-serif;
            display: inline;
        }

        &:before {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 3rem;
            height: 1.5rem;
            border: .1rem solid transparent;
            border-radius: 24px;
            background: #B5B5B5;
            content: "";


            .error.field & {
                box-shadow: 0 0 0px 2px #EA1742;
            }
        }

        &:after {
            display: block;
            position: absolute;
            top: .25rem;
            left: .25rem;
            width: 1rem;
            height: 1rem;
            transform: translate3d(0, 0, 0);
            border-radius: 50%;
            background: #FFFFFF;
            transition: all .25s ease-out;
            content: "";
        }
    }
}

#company-social {
    .under-input-label {
        width: 100%;
        //text-align: right;
        margin-top: -20px;

        p {
            font-size: 0.85rem;
        }
    }
}

#page-companies {

    .membership_package_name {
        font-size: 12px !important;
    }

    .gifty-table {
        .table {
            thead {
                tr {
                    th {
                        font-size: 1em;
                    }
                }
            }

            tbody {
                td {
                    padding: 0.2em 0.5em 0.2em 0;

                    @media screen and (max-width: 768px) {
                        padding-left: 0.5em;
                    }
                }
            }
        }
    }

    table.table {
        span.campaign-none {
            opacity: .33;
        }
    }
}

.gifty-color-picker {
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;

        .swatch, .swatch-picker {
            cursor: not-allowed !important;
        }
    }

    .swatch {
        cursor: pointer;
        width: 28px;
        height: 28px;
        margin: 2px;
        float: left;
        border-radius: 7px;
        border: 2px solid $grey-lighter;
    }

    .swatch-picker {
        cursor: pointer;
        width: 28px;
        height: 28px;
        margin: 2px;
        border: 2px solid $grey-lighter;
        border-radius: 7px;
        background: url('../images/colorpicker.png') center center no-repeat;
        float: left;
    }

    .palette-container {
        width: 150px;
        float: left;
        margin-left: 1em;

        @media screen and (min-width: 1216px) and (max-width: 1407px) {
            width: 145px;
            margin-left: 0;
        }
    }

    .color-picker-container {
        position: absolute;
        z-index: 10;
        box-shadow: 13px 22px 76px 0 rgba(213, 213, 213, 0.8);

        &.inline {
            position: static;
            z-index: 0;
            width: 200px;
            box-shadow: none;
            display: inline-block;
            float: left;

            .vc-chrome {
                width: auto;
            }

            .has-palette {
                //top: .6em;
                //position: relative;
            }
        }
    }

    .color-picker-close {
        line-height: 1;
        padding: .2rem .2rem 0 .2rem;
        margin-bottom: -0.4rem;
        background: #FFFFFF;
        border: 1px solid $grey-lighter;
        border-bottom: none;
        border-radius: 3px 3px 0 0;
        z-index: 2;
        position: relative;

        i {
            cursor: pointer;
        }
    }

    .swatch-selected {
        border-color: $primary;
    }

    .vc-chrome {
        box-shadow: none;
    }

    .vc-chrome-fields .vc-input__label {
        font-family: 'Nexa', sans-serif;
    }

    .control:not(.editor-container) {
        min-width: 192px;
    }

    .editor-container.cropped {
        margin: 5px 0;
        width: 140px;
    }
}

.loading-overlay .loading-icon:after,
.b-table.is-loading:after {
    border-radius: 50%;
}

html, body {
    height: 100%;
    width: 100%;

    &:has(.nav-menu.is-active) {
        height: 100vh;
        overflow: hidden;
    }
}

.select-invisible span {
    width: 100%;

    select {
        border: none;
    }
}

#page-giftcards {

    .advanced-filters-toggle {
        margin-top: -1rem;
    }

    .bordered-tag {
        text-transform: capitalize;
    }

    .difference-currency-title {
        padding-top: 27px;
    }
}

#page-shipment {
    .select-container {
        margin-left: 37px;
    }
}

#page-editor {
    color: #757575;

    .editor-container-height {
        height: 260px;
    }

    .label {
        font-family: 'nexa', sans-serif;
    }

    .editor-container, .inline + .palette-container {
        border: 1px solid $grey-lighter;
        border-radius: 6px;

        &:not(.has-palette) {
            position: inherit;
        }

        &:not(.library) {
            padding: 10px;
        }

        &.with-shadow {
            box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
        }

        &.palette-container {
            padding: 5px;
            margin: 0 0 0 10px;
            background: #FFFFFF;
            display: inline-block;
        }
    }

    .editor-button {
        border-radius: 27px;
        height: 54px;
        font-size: 21px;

        &[type=submit] {
            padding: 0 2.2em;
        }

        &.with-shadow {
            padding: 0 1em;
            -webkit-box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
            -moz-box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
            box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
        }
    }

    .editor-container-width {
        margin: 2em 0;
    }

    .color-container {
        flex: 0 0 164px;

        .gifty-color-picker {
            .editor-container {
                padding: 5px;
            }
        }
    }

    .pattern-column {
        flex: 0 0 150px;
        padding-right: 0;
    }

    .pattern-color-column {
        .color-container {
            flex: 0 0 140px;
        }

        @media screen and (min-width: 1216px) and (max-width: 1407px) {
            .columns {
                //margin-bottom: -0.75rem;
                .column {
                    //padding-bottom: 0;
                }
            }
        }
    }

    .editor-container-select span {

        &::after {
            //top: 75%;
            //right: 1em;
        }

        select {
            font-size: 14pt;
            padding-left: 1em;
            border: 1px solid $grey-lighter;
            border-radius: 6px;
            overflow-y: hidden;
            width: 100%;
            height: 55px;
            background: white;
            box-shadow: 0 -2px 20px 0 rgba(213, 213, 213, 0.3);
        }
    }

    // Start image uploader
    input[type="file"] {
        display: none !important;
    }

    section.small {
        height: 75px;
    }

    .drop-field, .drop-field div {
        width: 100%;
        height: 100%;
    }

    .scrollable {
        position: relative;
        height: 100%;
    }

    .library {
        padding: .5rem 0;
        //padding: .5rem 0;
        //padding: 23px 25px 25px 25px;
        //padding-bottom: 0;

        .action-buttons {
            position: relative;
            z-index: 1;

            .icon {
                color: white;
                text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
                position: absolute;
                top: 6px;
                right: 8px;

                .mdi {
                    font-size: 16px;
                }

                &:hover {
                    color: $primary;
                    cursor: pointer;
                }
            }
        }

        &.patterns {
            //padding: 0;

            .image-column-container {
                padding-bottom: .25rem;

                &:last-child {
                    padding-bottom: 0;
                }

                //margin: 0 0 .25em 0;

                //&:last-child {
                //	margin-bottom: 0;
                //}
            }
        }

        .scrollable {
            padding: 0 .5rem;
            //margin: -1.25em;

            .is-multiline {
                height: 100%;
                //margin: .5em;

                .image-column-container {
                    //padding: .25rem .5rem .25rem .5rem;

                    .image {
                        margin: .1rem;
                    }

                    //&:first-child {
                    //	padding-top: 0;
                    //}
                    //
                    //&:last-child {
                    //	padding-bottom: 0;
                    //}

                    .image img {
                        width: 100%;
                        user-drag: none;
                        user-select: none;
                        -moz-user-select: none;
                        -webkit-user-drag: none;
                        -webkit-user-select: none;
                        -ms-user-select: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .can-be-bordered {
        transition: border .1s linear;
        transition: opacity .1s linear;
        border: 2px solid #FFFFFF;
        border-radius: 6px;

        &.grey {
            border-radius: 3px;
            border-width: 2px;
            border-color: $grey-lighter;
        }

        &.is-bordered, &:hover {
            border-color: $primary;

            &.library-uploader {
                opacity: .6;
            }
        }
    }

    .upload-draggable:not(.small) {
        display: flex;
        flex-direction: column;
        justify-content: center;

        -webkit-box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
        box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);

        height: 100%;

    }

    .library-uploader div {
        height: 100%;
        padding: .1rem;
        display: flex;
        border: 2px solid transparent;

        .upload-draggable {
            cursor: pointer;
            font-size: 80%;
            text-align: center;
            margin: 1px;
            flex: 1 0 auto;
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px dashed #B5B5B5;
            border-radius: 5px;
        }
    }

    .pattern-container {
        background: no-repeat center center;
        background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        border: 1px solid #DBDBDB;
        border-radius: 3px;
    }

    .image-preview-container {

        width: 100%;
        height: 100%;

        &:not(.simple) {
            border: 1px solid $grey-lighter;
            border-radius: 6px;
            box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
        }

        .image-preview {
            width: 100%;
        }
    }

    .image-uploader-title {
        font-family: 'nexa', Helvetica, sans-serif;
    }

    .image-uploader-subtitle {
        font-size: 90%;
    }

    .branche-select {
        margin: .725rem 0;
    }

    // End image uploader

    .background-subtitle {
        font-size: 85%;
        height: 20px;
        margin-bottom: .5rem;
        margin-top: -1rem;
    }

    .textarea-cetered div label.label,
    .textarea-cetered div p.help {
        text-align: left;
        text-align-last: left;
    }

    .loading-screen {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000; // To place the bar above this.$loading component

        progress {
            left: calc(50% - (500px / 2));
            top: calc(50% + 10em);
            max-width: 500px;
            width: 80%;
            position: absolute;
        }
    }

    .editor-submit-container {
        margin-top: 1.5em;
    }

    .aspect-ratio-wrapper {
        width: 100%;
        position: relative;
        display: inline-block;
        text-align: center;

        &:after {
            padding-top: 70.6713780919%;
            display: block;
            content: '';
        }

        &.aspect-ratio-background:after {
            padding-top: 67.5%;
        }

        &.aspect-ratio-half {

            &.with-cropper {
                border: 1px solid $grey-lighter;
                border-radius: 6px;
                -webkit-box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
                box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
                width: 100%;
                overflow: hidden;
            }

            &:after {
                padding-top: 50%;
            }
        }

        .aspect-ratio-content {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;

        }
    }

    .giftcard-preview {
        top: 1em;
        position: sticky;
        position: -webkit-sticky;

        .giftcard-preview-label {
            position: relative;
            top: -600px;
            left: 102px;
        }

        canvas {
            -webkit-box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
            box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
        }
    }

    .loading-canvas {
        filter: blur(5px);
        -webkit-filter: blur(5px);
    }

    .cropper {

        overflow: hidden;

        .image-preview-container {

            .cropper-view-box {
                outline-color: $primary;
                outline: 1px solid $primary;
            }

            &.simple .cropper-view-box {
                outline: 0;
            }
        }

        .cropper-options {
            position: relative;
            float: right;
            z-index: 1;
            top: 10px;
            left: -10px;

            .control {

                .button {
                    box-shadow: 1px 3px 32px 0 rgba(50, 50, 50, 0.25);
                }
            }
        }

        .slider {
            margin-top: 1em;
        }
    }

}

#module-styling {
    #gifty-embedded {
        box-shadow: $box-shadow;
        border: 5px solid white;
        border-radius: 5px;
    }
}

.column.border-left {
    border-left: 1px solid $grey-lighter;
}

.footer {
    padding: 3rem 1.5rem;
}

#company-developers {
    #logs-history {
        margin-top: -55px;
    }
}

#company-details {

    @media screen and (max-width: 767px) {

        .field-body {
            margin-bottom: 1.5rem;
        }
    }
}

.mx-input-append {
    display: flex;
    align-items: center;
    justify-content: center;
}

.location-settings {
    max-height: calc(100vh - 40px);

    .giftcard-preview-left {
        float: left;
        margin: 5px 15px 15px 0;
        width: 50px;
        height: 35px;
        border-radius: 2px;
    }

    .giftcard-sleeve-pricing {
        font-size: 0.7em;
        position: relative;
        top: -3px;
    }
    .item-link {
        color: $grey-dark;
        text-decoration: underline;
    }
}

.loadable {
    @include loadable;
}

.is-danger-text {
    color: $red;
}

.notification-content p {
    color: white;
    font-size: 14px;
}

.pill-group {

    .pill {
        margin-right: 7px;
    }
}

.pill {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    white-space: nowrap;
    border-radius: 25px;
    padding: 6px 14px;
    color: #fff;

    &.is-small {
        padding: 5px 9px;
    }

    .icon {
        font-size: 14px;
        vertical-align: -2px;
    }

    strong {
        color: #fff;
    }

    &.is-small {
        font-size: 0.625rem;
        padding: 0.313rem 0.625rem;
    }

    &.black {
        background-color: #191919;
    }

    &.grey {
        background-color: #626262;
    }

    &.yellow {
        background-color: #e2b141;
    }

    &.blue {
        background-color: #547ba2;
    }

    &.green {
        background-color: $success;
    }

    &.aqua {
        background-color: #65bcc7;
    }

    &.red {
        background-color: #f0470f;
    }

    &.purple {
        background-color: #986ade;
    }
}

.columns.locations-form .column:not(:last-child) {
    padding-bottom: 0;

    .field {
        margin-bottom: 0;
    }
}

.columns.locations-form-switches {
    .column {
        padding-top: unset;
    }
}

.cursor-pointer {
    cursor: pointer;
}

a.disabled-link {
    color: rgba(0, 0, 0, 0.3)
}

.redacted-tag .tag {
    font-size: 11px !important;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    padding: 0 7px 0 7px;
    margin: 0 2px 0 2px;
    font-weight: normal !important;
}

// Apple SF Pro
@font-face {
    font-family: 'SF Pro Display';
    font-weight: 100;
    src: url('../fonts/apple/sf-pro-display_ultralight.woff2') format('woff2');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: 200;
    src: url('../fonts/apple/sf-pro-display_thin.woff2') format('woff2');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: 300;
    src: url('../fonts/apple/sf-pro-display_light.woff2') format('woff2');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: 400;
    src: url('../fonts/apple/sf-pro-display_regular.woff2') format('woff2');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: 500;
    src: url('../fonts/apple/sf-pro-display_medium.woff2') format('woff2');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: 600;
    src: url('../fonts/apple/sf-pro-display_semibold.woff2') format('woff2');
}

@font-face {
    font-family: 'SF Pro Display';
    font-weight: 700;
    src: url('../fonts/apple/sf-pro-display_bold.woff2') format('woff2');
}

.fill-wave-pattern {
    z-index: 1;
    position: absolute;
    background-image: url('../images/wallet/wave-pattern.png');
    background-repeat: repeat;
    opacity: 0.4;
    width: 100%;
    height: 100%;
}

#webhook-select .vue-treeselect__multi-value-item-container {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
}

.acl-blockage-icon {
    position: relative;
    top: 0.125rem;
    font-size: 1rem;
}

.developer-title {
    position: relative;
    top: 0.5rem;
}

#developers-warning-icon {
    position: relative;
    top: 0.188rem;
    left: -0.625rem;
    font-size: 1.063rem;
}
