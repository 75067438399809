#builder {

    .background-radio {
        margin-bottom: 0;
    }

    .image-warnings {
        font-size: 0.7rem;
    }

    .font-select-container {

        .font-select, .font-options div {
            font-size: 16pt;
            padding: 11.2px;
            padding-left: 1em;
        }

        &.money {
            .font-select:first-letter, .font-options div:first-letter {
                font-size: 70%;
            }
        }

        .font-select {
            border: 1px solid $grey-lighter;
            border-radius: 6px;
            overflow-y: hidden;
            width: 100%;
            background: #FFFFFF;
            position: relative;
            cursor: pointer;
            user-select: none;
            -webkit-box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
            box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);

            &::after {
                border: 1px solid #E81348;
                border-right: 0;
                border-top: 0;
                content: " ";
                display: block;
                height: 0.5em;
                pointer-events: none;
                position: absolute;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transform-origin: center;
                transform-origin: center;
                width: 0.5em;
                margin-top: -0.375em;
                right: 1.125em;
                top: 50%;
                z-index: 4;
            }
        }

        .font-options {
            margin: .5rem 0;
            position: absolute;
            width: auto;
            min-width: 250px;
            z-index: 5;
            -webkit-box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
            box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
            border: 1px solid $grey-lighter;
            border-radius: 6px;
            background: #FFFFFF;

            div {
                padding: 8px 16px;
                cursor: pointer;
                user-select: none;

                &:hover {
                    background: $grey-lighter;
                }

                &.selected {
                    background: $light;
                }

                &:not(:first-child) {
                    border-top: 1px solid $grey-lighter;
                }
            }
        }

    }

    .custom-template-link {
        font-family: nexa, sans-serif;
        font-weight: bold;
        text-decoration: underline;
        display: block;
        text-align: right;
    }

    @media screen and (max-width: 767px) {

        .custom-template-link {
            text-align: left;
        }

        // First element
        &.first-element {
            padding-top: 300px;
        }
    }

    //margins
    .m-bt-15 {
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .m-b-15 {
        margin-bottom: 15px !important;
    }

    .m-b-0 {
        margin-bottom: 0 !important;
    }

    // Accordion item
    .builder-item {
        font-weight: bold;
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #e5e4e4;
        padding: 6px 0;
        line-height: 1;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        // First
        &.first {
            margin-top: 0;

        }

        // Span (large)
        span {
            font-size: 19px;
            color: #1f1f1f;
            opacity: .4;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            -ms-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;

            @media screen and (max-width: 767px) {
                font-size: 15px;
            }
        }

        // Small
        small {
            font-size: 16px;
            color: #6a7272;
            opacity: .4;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            -ms-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;

            @media screen and (max-width: 767px) {
                font-size: 14px;
            }
        }

        // Active
        &.active, &:hover {

            // Span
            span {
                opacity: 1;
            }

            // Small
            small {
                opacity: 1;
                color: #1f1f1f;
            }
        }
    }

    // Sjabloon
    .sjabloon-wrapper {

        .columns.is-multiline {
            margin: 0;

            @media screen and (max-width: 767px) {
                margin: 0 -5px;
            }

            .template-container {

                cursor: pointer;
                padding: 5px;

                .image {
                    img {
                        box-shadow: 0px 14px 32px 0px rgba(213, 213, 213, 0.24);
                    }

                    margin-bottom: .25em;
                    height: auto;
                    width: 100%;

                    &.is-bordered img {
                        border-radius: 3px;
                    }
                }

                &:first-child, &:nth-child(4n+4) {
                    padding-left: 0;
                }

                &:nth-child(3n+3) {
                    padding-right: 0;
                }

                label {
                    width: 100%;
                    justify-content: center;
                }
            }

            .image.can-be-bordered {
                font-size: 0;
                border: 2px solid transparent;

                &:hover, &:focus {
                    border-color: #E81348;
                }

                // Image
                img {
                    width: 100%;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                }

                // Is bordered
                &.is-bordered {
                    border-color: #E81348;
                }
            }
        }
    }

    // Address wrapper
    .address-wrapper {

        .textarea-centered {

            .editor-container-textarea {
                width: 100%;
                text-align: center;
                box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
                border: 1px solid $grey-lighter;
                border-radius: 6px;
                padding: 2px 4px;
                position: inherit;
                overflow: hidden;
                background: white;

                textarea {
                    width: 291px;
                    border: 0;
                    margin: auto;
                    resize: none;
                    min-width: 1px;
                    text-align: center;
                    box-shadow: none;
                }
            }

        }

        .textarea-cetered div label.label,
        .textarea-cetered div p.help {
            text-align: left;
            text-align-last: left;
        }

        & + .textarea-counter {
            float: right;
            position: relative;
            top: -43px;
            right: 8px;
        }
    }

    // Color container
    .color-container {

    }

    // Progress
    .builder-progress {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 12;
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: none;
        background-color: #a1e0ba;

        @media screen and (max-width: 767px) {
            display: block;
        }

        // List item
        li {
            //width: 20%;
            box-sizing: border-box;
            float: left;
            background-color: #3BC173;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            -ms-transition: all .3s;
            -o-transition: all .3s;
            transition: all .3s;
            height: 5px;

            &.progress-20 {
                width: 20%;
            }
        }
    }

    // Pattern edit
    .pattern-edit {

        // Color container
        .color-container {

            @media screen and (max-width: 767px) {

                // Editor container
                .editor-container {
                    //width: 100%;
                    width: auto;
                }

                // Color picker
                .gifty-color-picker {

                    // Color picker container (popup)
                    .color-picker-container {
                        left: 0;
                        width: 70%;
                    }

                    // Swatch
                    .swatch {
                        width: 35px;
                        height: 35px;
                    }

                    // Swatch picker
                    .swatch-picker {
                        width: 31px;
                        height: 31px;
                    }
                }
            }
        }

        // Label
        .label {

            @media screen and (max-width: 767px) {
                &.label-last {
                    margin-top: 15px;
                }
            }
        }
    }

    // Elements
    @media screen and (max-width: 767px) {
        #page-editor .library-uploader div .upload-draggable {
            font-size: 50%;
        }

        #page-editor .editor-container-height {
            height: auto;

            .colorpicker-suggested-colors.palette-container {
                width: 95px;
                float: right;

                .swatch {
                    width: 39px;
                    height: 39px;
                }
            }
        }
    }

    // Animations
    .slide-enter-active {
        transition: all 1s linear;
        overflow: hidden;
    }

    .slide-enter, .slide-leave-to, .slide-leave {
        min-height: 0;
        max-height: 0;
    }

    .slide-enter-to {
        min-height: 100px;
        max-height: 1000px;
    }

}

// Preview
.giftcard-preview {
    font-size: 0;

    @media screen and (max-width: 767px) {
        position: fixed !important;
        top: 0 !important;
        left: 0;
        right: 0;
        z-index: 10;
    }

    // Label
    label {

        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    // Controls
    .giftcard-side-controls {
        gap: .4rem;
        display: none;
        justify-content: center;

        @media screen and (min-width: 1024px) {
            display: flex;
        }
    }

    // Canvas
    canvas {
        max-width: 100%;
        height: auto !important;
        border-bottom: 1px solid #e5e4e4;

        @media screen and (min-width: 1024px) {
            border-color: transparent;
        }
    }
}
