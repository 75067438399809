
/*! Generated by Fontspring (https://www.fontspring.com) on February 15, 2018
*
* Fully installable fonts can be purchased at http://www.fontspring.com
*
* The fonts included in this stylesheet are subject to the End User License you purchased
* from Fontspring. The fonts are protected under domestic and international trademark and
* copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
* distributing this font software.
*
* (c) 2010-2018 Fontspring
*
*
*/



@font-face {
    font-family: 'nexa';
    src: url('../fonts/nexa-regular.woff2') format('woff2'),
    url('../fonts/nexa-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/*! Generated by Fontspring (https://www.fontspring.com) on February 15, 2018
*
* Fully installable fonts can be purchased at http://www.fontspring.com
*
* The fonts included in this stylesheet are subject to the End User License you purchased
* from Fontspring. The fonts are protected under domestic and international trademark and
* copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
* distributing this font software.
*
* (c) 2010-2018 Fontspring
*
*
*/



@font-face {
    font-family: 'nexa';
    src: url('../fonts/nexa-bold.woff2') format('woff2'),
    url('../fonts/nexa-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
