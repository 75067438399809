.modal.is-active {
    .modal-content {
        border-radius: $radius-large;

        @media screen and (min-width: 769px) {
            width: 640px;
        }

        &.model-large {

            @media screen and (min-width: 769px) {
                width: 750px;
            }
        }
    }
}

.popup-dialog {
    padding: 1.25rem 0;
    background-color: white;
    border-radius: $radius-large;
    color: #4A4A4A;
    display: block;

    p {
        font-size: 1rem;
    }

    .popup-title {
        padding: 1.25rem 2.5rem;

        .title.popup-title-subtitle {
            color: #5f5f5f;
            margin-bottom: 1.5rem;
        }
    }

    .popup-body {
        padding: 1.25rem 2rem;

        &.popup-rows {
            padding: 1.25rem 0.75rem;

            @media screen and (max-width: 768px) {
                padding: 1.25rem 0.75rem;
            }

            .columns {
                border-bottom: 1px solid $grey-lighter;

                &:last-child {
                    border-bottom: 0;
                }

                &.is-indented {
                    padding-left: 2rem;
                }

                .column {
                    padding-left: 2.5rem;
                    padding-right: 2.5rem;

                    &:first-child {
                        padding-top: 1.45rem;
                        padding-bottom: 1.45rem;

                        @media screen and (max-width: 768px) {
                            padding-bottom: 0;
                        }
                    }

                    &.is-vcentered {
                        display: flex;
                        align-items: center;
                    }

                    label {
                        font-weight: normal;
                    }

                    .field {
                        margin-bottom: .75rem;

                        &:last-child {
                            margin-bottom: 0;

                            @media screen and (max-width: 768px) {
                                margin-bottom: .75rem;
                            }
                        }
                    }

                    .field-body:last-child {

                        .field {
                            margin-bottom: 0;

                            @media screen and (max-width: 768px) {
                                margin-bottom: .75rem;
                            }
                        }
                    }
                }
            }
        }

        pre.code-container {
            white-space: normal;
            word-break: break-all;
            height: 150px;
            border-radius: 5px;
        }
    }

    .popup-footer {
        padding: 1.25rem 2.5rem;

        button.button {
            margin-left: 0.75rem;
        }

        a {
            display: inline-block;
            color: #4A4A4A;
            text-decoration: underline;
            margin-top: .7rem;
        }
    }
}

.popup-dialog .popup-title {
    padding: 1.5rem 0 0 1.9rem
}

.popup-dialog {
    padding-top: 0;
}

.popup-dialog .popup-footer {
    padding: 1.25rem 1.25rem 0;
}

.popup-title {
    border-bottom: 0.063rem solid rgba(239, 239, 239, 0.94);
    position: relative;
    padding: 1rem;

    .title {
        position: relative;
        top: 0.125rem;
        max-width: 80%;

        &.is-1 {
            font-size: 1.4rem;
        }
    }

    p {
        padding: 0 0 1rem 0;
        max-width: 80%;
    }

    .popup-close {
        padding: 0 2.8rem 0 2rem;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        border-left: 0.063rem solid rgba(239, 239, 239, 0.94);
        height: 100%;

        i.icon {
            position: absolute;
            top: 52%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.875rem;
        }

        &:hover {
            color: #000000;
        }
    }
}

.popup-body .popup-buttons {
    display: flex;
    justify-content: center;
    padding-top: 1.938rem;
    padding-bottom: 2rem;

    button:not(:last-child) {
        margin-right: 1rem;
    }

    button {
        width: 8.438rem;
        padding: 1.5rem 0;
        box-shadow: 0 0.75rem 1.875rem 0 #D6D6D680;
        background-color: white;
        color: #1f1f1f !important;
        border: 0.125rem solid rgba(0, 0, 0, 0);
        border-radius: 0.625rem;
        text-align: center;

        &.active {
            border: 0.125rem solid #DE134E;

            img {
                filter: grayscale(0);
            }

            p, i {
                color: #DE134E !important;
                fill: #DE134E !important;
            }
        }

        img {
            margin-bottom: 1.1rem;
            filter: grayscale(1);
        }

        i.icon {
            font-size: 3rem;
        }

        p {
            color: #000;
            font-size: 0.875rem;
        }

        &:hover {
            cursor: pointer;

            img {
                filter: grayscale(0);
            }

            p, i {
                color: #DE134E !important;
                fill: #DE134E !important;
            }
        }
    }
}

.popup-footer {
    border-top: 0.063rem solid rgba(239, 239, 239, 0.94);
    text-align: right;

    a {
        text-decoration: none !important;
    }
}
