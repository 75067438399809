@import "bulma";

.tab-view {
    p {
        color: $grey-darker;
        font-size: 1rem;
        margin-bottom: .65rem;

        a {
            color: $grey-darker;

            &.external:after {
                @extend .icon;
                content: " \e89e";
                font-size: 0.875rem !important;
                margin-left: .2rem;
                vertical-align: -.1rem !important;
            }
        }
    }

    .tab-container {
        padding-bottom: 5rem;

        @media only screen and (min-width: 769px) {
            padding-left: 2.5rem;
        }
    }

    .tab-header {
        @extend .title;
        @extend .is-3;

        font-size: 1.25rem;
        padding-bottom: 1rem;
        padding-top: .6rem; // To align with the first nav item
        margin-bottom: 3rem !important;
        border-bottom: 2px solid $grey-white;
    }

    .tab-description {
        max-width: 65%;
        padding-bottom: 1rem;
        margin-bottom: 3rem !important;
    }

    .tab-subheader {
        @extend .title;
        @extend .is-4;
        font-size: 1rem;
        margin-bottom: 0;

        @media only screen and (min-width: 769px) {
            margin-bottom: 1.31rem !important;
        }
    }

    .tab-table-container {
        overflow-x: scroll;
        scrollbar-width: none;

        table.tab-table {
            min-width: 100%;

            thead {

                th {
                    font-weight: 600;
                    white-space: nowrap;

                    &.is-numeric {
                        text-align: right;
                    }

                    &:first-of-type {
                        padding-left: 0;
                    }

                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }

            tbody, tfoot {

                strong {
                    font-weight: 600;
                }

                tr.child-row {
                    font-size: 0.8125rem;
                }

                tr.total-row {
                    font-weight: 600;
                }

                td {
                    height: 41.5px;
                    vertical-align: middle;
                    white-space: nowrap;

                    &.is-numeric {
                        text-align: right;
                        font-variant-numeric: lining-nums tabular-nums;
                    }

                    &.is-negative-value {
                        color: $red;
                    }

                    &:first-of-type {
                        padding-left: 0;
                    }

                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }
        }
    }

    .gifty-table .table {
        thead tr th {
            font-size: .94rem;
        }
    }

    .column-form {

        .field-header {
            font-size: 1rem;
            margin-bottom: .31rem;
        }

        p {
            color: #6A7272;
            font-size: 0.875rem;
        }
    }
}
