.inline-menu {
	list-style: none;
	background-color: #F8FAFB;
	border-bottom: 1px solid #EBEDEE;
	li {
		display: inline-block;
		padding: 15px;
		a {
			color: #1F1F1F;
			font-size: 15px;
			text-transform: uppercase;
			&.is-active {
				color: $primary;
				padding-bottom: 15px;
				border-bottom: 3px solid $primary;
			}
		}
	}
}

#module-placement {

	.file-button {
		figure {
            height: 1.5em;
            width: 1.5em;
			margin-right: .75em;
            margin-bottom: 0;
		}
		.button {
			text-decoration: underline;
		}
	}

	.web-developer-help-form {
		textarea {
			height: 305px;
		}
	}

	.confirmation-message {
		min-height: 611px;
	}

	.module-box {
		margin-bottom: 1rem;

		&:last-child .image-box.with-text .image-container {
			background-color: $primary;
		}

		&:hover:not(.disabled) {
			cursor: pointer;
		}

		.content {
			//min-height: 90px;
		}

		.image-box {
			margin: 3px; // Active state border fix
			border-radius: $radius-large;
			background-color: #FFFFFF;
			position: relative;
			z-index: 2;
			-webkit-box-shadow: 0px 51px 76px 0px rgba(213, 213, 213, 0.65);
			box-shadow: 0px 51px 76px 0px rgba(213, 213, 213, 0.65);

			&.with-text {
				//height: 360px;

				.image-container {
					height: 160px;
					border-radius: $radius-large $radius-large 0 0;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #EEF2F5;

					img {
						height: 115px;
					}
				}

				.text-container {
					padding: 24px;

					.title {
						font-family: 'nexa', Helvetica, sans-serif;
					}

					.content {
						position: relative;
						z-index: 2;
					}
				}
			}

			&.active {
				margin: 0;
				border: 3px solid #E81348;
				position: relative;

				&.with-text {
					.image-container {
						border-radius: 8px 8px 0 0;

					}
				}

				&:after {
					content: "";
					width: 50px;
					height: 50px;
					background-color: #FFFFFF;
					border: 3px solid #E81348;
					border-left-color: #FFFFFF;
					border-top-color: #FFFFFF;
					position: absolute;
					bottom: -50px;
					left: 50%;
					transform: translate(-50%, -50%) rotate(45deg);
					filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=5);
					border-bottom-right-radius: 10px;
				}
			}
		}

		.example-url {
			display: inline-block;
			position: relative;
			margin-top: 10px;

			a {
				text-decoration: underline;
				font-size: 16px;
				padding-left: 15px;
			}
		}

		&.disabled {
			opacity: 0.5;
		}
	}

	.module-placement-content {
		margin-top: -125px;
		padding-top: 150px;
		padding-bottom: 150px;
	}

	div .section.container {
		padding: 5rem 1.5rem;

		//.columns .column.is-one-third {
		//	padding: 1em 0;
		//}
	}
}

#widget-settings {

	.widget-icon-style {

		.custom-colorpicker {
			padding: 0.44rem;
			box-shadow: 0px 5px 27px 3px rgba(213, 213, 213, 0.35);
		}

		.custom-select {
			.select {
				box-shadow: 0px 5px 27px 3px rgba(213, 213, 213, 0.35);
			}
		}
	}

	.widget-code {

        &.spacing {
            padding-top: 3rem;
        }

        .code-box {
            border: 1px solid $grey-lighter;
            border-radius: 6px;
            background-color: white;
            padding: 0.7rem 1.1rem;
            box-shadow: 0px 5px 27px 3px rgba(213, 213, 213, 0.35);

            pre {
                background-color: #FFFFFF;
                color: #6A7272;
                padding: 0px;
            }
        }
    }

	.widget-example-box {
		position: relative;

		.browser-example {
			box-shadow: 0px 51px 76px 0px rgba(213, 213, 213, 0.8);
			@media screen and (min-width: 1410px) {
				width: 120%;
			}
		}

		.message-box {
			position: absolute;
			width: auto;
			max-width: 100%;
			display: inline-block;
			box-sizing: border-box;
			padding: 12px 20px;
			color: #FFFFFF;
			font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
			font-size: 14px;
			line-height: 1.2;
			word-break: break-all;
			border-radius: 19px;
			box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
			opacity: 0; // Default to prevent jumping around elements

			p {
				color: #FFFFFF;
			}

			&.left {
				left: 100px;
				opacity: 1;

				&.top {
					top: 64px;
				}

				&.bottom {
					bottom: 30px;
				}
			}

			&.right {
				right: 100px;
				opacity: 1;

				&.top {
					top: 64px;
				}

				&.bottom {
					bottom: 30px;
				}
			}
		}

		.widget-icon-box {
			position: absolute;
			opacity: 0; // Default to prevent jumping around elements

			&.bottom {
				opacity: 1;
				bottom: 25px;

				&.left {
					left: 25px;
				}

				&.right {
					right: 25px;
				}
			}

			&.top {
				opacity: 1;
				top: 50px;

				&.left {
					left: 25px;
				}

				&.right {
					right: 25px;
				}
			}

			.widget-icon {
				font-size: 34px;
				line-height: 1;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background: #F4E54F;
				text-align: center;
				padding-top: 12px;
			}
		}
	}
}

#module-styling {
	.custom-colorpicker {
		padding: 0.44rem;
		border: 1px solid $grey-lighter;
		border-radius: 6px;
		display: inline-block;
		box-shadow: 0px 5px 27px 3px rgba(213, 213, 213, 0.35);
	}

	.gifty-widget-box {
		background: url('../images/module/abstract.svg') center no-repeat;
		background-size: 100%;

		@media screen and (min-width: 1330px) {
			background-size: 80%;
		}

		.gifty-widget {
			width: 300px;
			margin: 0 auto;
		}

	}
}
