.options-table {
    color: #4A4A4A;
    font-size: .9rem;
	background-color: white;
	border-radius: $radius;
	-webkit-box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
	box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
	display: block;
	margin-bottom: 2em;

    strong {
        color: #4A4A4A;
    }

	.columns, .columns .column {
		margin: 0;
		padding: 0;
	}

	.columns {
		padding: .3em 2em;

        @media screen and (max-width: 1023px) {
            padding: 1em 1em;
        }

		@media screen and (max-width: 768px) {
			&.options-item {
				padding: 1em 2em;
			}
		}

        &:not(:first-child) {
            min-height: 64px;
        }

		&:not(:last-child) {
			border-bottom: 1px solid $grey-lighter;
		}

		.column {
			align-self: center;

            @media screen and (max-width: 1023px) {
                padding: .2rem 0;
            }
		}

		&.no-border {
			border: none;
			min-height: 0 !important;
		}
	}

    .summary {
        background: #f6f9fb;
        border-bottom: 1px solid #ececec;

        .columns {
            margin: 0 2em;
            padding: 0;
            border-color: #e2eaf0;

            &:not(:first-child) {
                min-height: 55px;
            }
        }
    }

	.info {
		border-radius: 50%;
		border: 1px solid $grey-lighter;
		font-size: 75%;
		padding: 1px 5.5px;
		font-family: "Times New Roman";
		text-transform: lowercase;
		color: $grey-lighter;
	}

	.options-link {
		text-decoration: underline;
		color: $grey-light;
		cursor: pointer;
		margin-left: 5px;
	}

	.options-header {
		text-transform: uppercase;
		min-height: 75px;
	}

	.options-item {

		.column {

			.options-heading {
				font-weight: bold;
				font-size: 130%;
				font-family: 'nexa';
				vertical-align: top;

				&.error {
					color: $danger;
				}
			}

			.tag {
				margin-right: 2px;
			}

			small {
                font-size: 0.875rem;
                line-height: 1.1;
				display: block;
			}

            .overflow-ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .overflow-wrap {
                overflow-wrap: break-word;
            }

			&:before {
				float: left;
				font-size: 90%;
				text-transform: uppercase;
				width: 33%;
				min-width: 82px;
			}
		}

		@include loadable;
	}

	.options-footer {
		color: $grey-light;
		padding: 1em 2em;
		align-items: center;

		.extra-list-item {
			margin: 0;

			@media screen and (max-width: 768px) {
				margin-bottom: .5em;
			}
		}

		.options-subtitle {
			font-size: 88%;
		}
	}

	.options-form {
		.form-title {
			min-height: 75px;
			align-items: center;
			display: flex;

			.is-full {
				.title {
					font-size: 140%;
					margin-bottom: 0;
				}
			}
		}

		.options-footer {
			justify-content: flex-end;
		}
	}
}
