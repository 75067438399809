@import '~@riophae/vue-treeselect/dist/vue-treeselect.css';

.vue-treeselect {
	@extend .form;

	&.vue-treeselect--open {

		.vue-treeselect__control {
			border-radius: $radius $radius 0 0;
		}
	}

	&.vue-treeselect--focused:not(.vue-treeselect--open) .vue-treeselect__control {
		box-shadow: none;
		border-color: #DE134E;
	}

	.vue-treeselect__label {
		font-weight: normal !important;
	}

	.vue-treeselect__control {
		color: #6A7272;
		padding: 0;
		height: auto;
		border-radius: $radius;
		box-shadow: 0px 5px 27px 3px rgba(213, 213, 213, 0.35);
		@extend .control;
        border-color: $grey-lighter;

		.vue-treeselect__control-arrow-container {
			display: none;
		}

		.vue-treeselect__value-container {
			height: auto;
			padding: $control-padding-vertical $control-padding-horizontal;

			.vue-treeselect__placeholder,
			.vue-treeselect__input-container {
				color: #6A7272;
				font-size: 1em;
				line-height: 1.5;
				padding: $control-padding-vertical $control-padding-horizontal;
			}

			.vue-treeselect__input-container {
				padding: 0;

				.vue-treeselect__input {
					line-height: 1.5;
					vertical-align: top;
					padding: 0;
				}
			}

			.vue-treeselect__multi-value {
				margin: 0;

				&:last-child {
					margin: 0;
				}

				.vue-treeselect__multi-value-item-container {
					padding-top: 0;

					.vue-treeselect__multi-value-item {
						margin-bottom: 0;
						@extend .tag;

						.vue-treeselect__multi-value-label {
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							padding: 0;
						}

						.vue-treeselect__value-remove {
							padding: 0;
							@extend .delete;
							@extend .is-small;

							&:last-child {
								margin-left: .25rem;
								margin-right: -.375rem;
							}

							svg {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	.vue-treeselect__menu-container {

		.vue-treeselect__label-container {

			&:hover {
				.vue-treeselect__checkbox {
					border-color: $primary;
				}
			}
		}

		.vue-treeselect__control {
			.vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused) &:hover {
				border-color: $primary;
			}

			.vue-treeselect--focused:not(.vue-treeselect--open) & {
				border-color: $primary;
			}
		}

		.vue-treeselect__checkbox--unchecked {

			.vue-treeselect__label-container:hover & {
				border-color: $primary !important;
			}
		}

		.vue-treeselect__checkbox--checked,
		.vue-treeselect__checkbox--indeterminate {
			border-color: $primary;
			background: $primary;

			.vue-treeselect__label-container:hover & {
				border-color: $primary;
			}
		}
	}
}

.field {
    .vue-treeselect {
        .vue-treeselect__limit-tip {
            padding: 0;

            .vue-treeselect__limit-tip-text {
                color: #6A7272;
                font-size: 1rem;
                font-weight: normal;
                margin: 0;
                padding: 0;
            }
        }

        //vue-treeselect__placeholder vue-treeselect-helper-zoom-effect-off vue-treeselect__multi-value-item--transition-move

        //vue-treeselect__input-container vue-treeselect__multi-value-item--transition-enter-to

        .vue-treeselect__control {

            .vue-treeselect__value-container {
                padding: 20px 12px 5px 12px;

                .vue-treeselect__placeholder {
                    padding: 20px 12px 5px 12px;
                }

                .vue-treeselect__input-container {
                    padding: 0;
                    //padding: 20px 12px 5px 12px;
                }
            }

        }
    }
}
