.capitalize {
	display: block;

	&::first-letter {
		text-transform: uppercase;
	}
}

.slide-down-enter-active, .slide-down-leave-active {
	overflow-y: hidden;
	max-height: 500px; /* approximate max height */

	transition-property: all;
	transition-duration: .5s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-down-enter, .slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
	height: 0;
}

.fade-enter-active, .fade-leave-active {
	transition: all .1s linear;
}

.fade-enter, .fade-leave-to {
	opacity: 0;
}

.fade-enter-to, .fade-leave {
	opacity: 1;
}


.autocomplete-container {
    position: relative;

	.autocomplete-options {
        list-style-type: none;
		top: 48px;
		margin: .5rem 0;
        padding: 0;
		position: absolute;
		width: auto;
		min-width: 250px;
		z-index: 5;
		-webkit-box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
		box-shadow: 0 12px 65px 0 rgba(213, 213, 213, 0.7);
		border: 1px solid $grey-lighter;
		border-radius: 6px;
		background: #FFFFFF;
		max-height: 240px;
		overflow-y: auto;

		li {
            margin: 0;
			padding: 8px 16px;
			cursor: pointer;
			user-select: none;

			&:hover {
				background: $grey-lighter;
			}
			&.selected {
				background: $light;
			}
            &:focus {
                background: #cee3fb;
            }

			&:not(:first-child) {
				border-top: 1px solid $grey-lighter;
			}
		}
	}
}

@mixin loadable() {
	&.is-loading {
		position: relative;
		pointer-events: none;
		opacity: 0.5;
		&:after {
			animation: spinAround 500ms infinite linear;
			border: 0.25em solid $grey-lighter;
			border-radius: 50%;
			border-right-color: transparent;
			border-top-color: transparent;
			content: "";
			display: block;
			position: absolute;
			top: calc(50% - 1.5em);
			left: calc(50% - 1.5em);
			width: 3em;
			height: 3em;
		}
	}
}
